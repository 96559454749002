import React, { useContext, createContext, useState, useEffect } from "react";
import {   BrowserRouter as Router,
  useHistory} from 'react-router-dom';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import axios from 'axios';


// defino los axios generales
axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;

export function Header() {
    

    const history = useHistory();

  // Carga de variables globales
  const { cuentaPL, setCuentaPL } = useVariablesGlobales();
  const {authTokenExist, setAuthTokenExist} = useVariablesGlobales();
  const {authToken, setAuthToken} = useVariablesGlobales();

  const [isVisible, setIsVisible] = useState(true);
  const id_local = localStorage.getItem('idUsuario') ? JSON.parse(localStorage.getItem('idUsuario')) : null;
const nombre_local = localStorage.getItem('nombre') ? JSON.parse(localStorage.getItem('nombre')) : 'USUARIO';
const apellidos_local = localStorage.getItem('apellidos') ? JSON.parse(localStorage.getItem('apellidos')) : null;
const imagen_local = localStorage.getItem('imagen') ? JSON.parse(localStorage.getItem('imagen')) : 'PLe_Profile-Pic_2024.png';

  const handleHide = () => {
    setIsVisible(false);
  };

  // Configuro y cargo variables del servidor
  const [tituloTemporada, setTituloTemporada] = useState(null);


    function cerrarSesion(){
        if (authTokenExist) {
          const token = JSON.parse(localStorage.getItem('authLaravel'));
          axios.get('https://plsystem.quarkservers2.com/api/logout', {
            headers: {
              'Authorization': 'Bearer '+token
            }
          })
          .then(function (response) {
            localStorage.removeItem('authLaravel');
            localStorage.removeItem('idUsuario');
            localStorage.removeItem('Distribuidor');
            localStorage.removeItem('DistribuidorAlt');
            localStorage.removeItem('totalCreditos');
            localStorage.removeItem('Region');
            localStorage.removeItem('RegionAlt');
            localStorage.removeItem('carrito');
            localStorage.removeItem('imagen');
            localStorage.removeItem('apellidos');
            localStorage.removeItem('idUsuario');
            localStorage.removeItem('nombre');
            setAuthTokenExist(false);
            console.log('Se supone que si cerró sesión');
            history.replace('/login');
          })
          .catch(function (error) {
            // handle error
            console.log('No se pudo cerrar sesión El error fue:'+error);
            localStorage.removeItem('authLaravel');
            localStorage.removeItem('idUsuario');
            localStorage.removeItem('Distribuidor');
            localStorage.removeItem('DistribuidorAlt');
            localStorage.removeItem('totalCreditos');
            localStorage.removeItem('Region');
            localStorage.removeItem('RegionAlt');
            localStorage.removeItem('carrito');
            localStorage.removeItem('imagen');
            localStorage.removeItem('apellidos');
            localStorage.removeItem('idUsuario');
            localStorage.removeItem('nombre');
            setAuthTokenExist(false);
            history.replace('/login');
          })
          .finally(function () {
            // always executed
          });
        }
      }

      
      // FUNCIÓN ESPECIAL PARA CERRAR SESIÓN A DISTANCIA BORRAR AL TERMINAR
      useEffect(() => {
        if (id_local === 475) {
          cerrarSesion();
        }
      }, [id_local]);


  return (
    <div>

        {/* Start Campain Area  */}
        {/* {isVisible && (
        <div className="rbt-header-campaign rbt-header-campaign-1 rbt-header-top-news bg-image1 d-none d-lg-block">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner justify-content-center">
                                <div className="content">
                                    <a href="/ilovepanduit"><span className="rbt-badge variation-02 bg-color-primary color-white radius-round">Contesta Ahora</span></a>
                                    
                                    <span className="news-text color-white-off" style={{fontSize:'20px'}}>  </span>
                                    <span className="news-text color-white" style={{fontSize:'14px'}}>
                                    Contesta y gana con nuestra última trivia  <b> I  ❤️ Panduit.</b>
                                    
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="icon-close position-right">
            <button className="rbt-round-btn btn-white-off bgsection-activation" onClick={handleHide}>
                    <i className="feather-x"></i>
                </button>
            </div>
        </div>
         )}  */}
        {/* End Campain Area  */}

        {/* Start Header Area */}
        <header className="rbt-header rbt-header-9">
            <div className="rbt-sticky-placeholder"></div>


            <div className="rbt-header-wrapper  header-not-transparent header-sticky">
                <div className="container">
                    <div className="mainbar-row rbt-navigation-end align-items-center">
                        <div className="header-left rbt-header-content">
                            <div className="header-info">
                                <div className="logo">
                                    <a href="/">
                                        <img src="/assets/images/micrositio/PL_HeadLogo_2024.png" alt="Education Logo Images" /> 
                                    </a>
                                </div>
                            </div>
                            <div className="header-info">
                                <div className="rbt-category-menu-wrapper">
                                </div>
                            </div>
                        </div>
                        
                        <div className="rbt-main-navigation d-none d-xl-block">
                        {authTokenExist ? (
                                <><div>
                                        <nav className="mainmenu-nav">
                                            <ul className="mainmenu">
                                                <li className="with-megamenu has-menu-child-item">
                                                    <a href="/temporadas">TEMPORADAS <i className="feather-chevron-down"></i></a>
                                                    {/* Start Mega Menu  */}
                                                    <div className="rbt-megamenu grid-item-2">
                                                        <div className="wrapper">
                                                            <div className="row row--15">
                                                                <div className="col-lg-12 single-mega-item">
                                                                    <ul className="mega-menu-item">
                                                                        <li><a href="/temporadas">Temporada 2024</a></li>
                                                                        <li><a href="/temporada/10">Temporada 2023</a></li>
                                                                        <li><a href="/temporada/11">Temporada 2022</a></li>
                                                                        <li><a href="/temporada/12">Temporada 2021</a></li>
                                                                    </ul>
                                                                    
                                                                </div>
                                                            </div>
                                                            {/* {<div className="row">
                                                                <div className="col-lg-12">
                                                                    <ul className="nav-quick-access">
                                                                        <li><a href="/otras-sesiones"><i className="feather-play-circle"></i> Sesiones Extras</a></li>
                                                                        <li><a href="/otras-sesiones"><i className="feather-play-circle"></i> Sesiones Especiales</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>} */}
                                                        </div>
                                                    </div>
                                                    {/* End Mega Menu  */}
                                                </li>
                                                <li className="with-megamenu has-menu-child-item position-static">
                                                    <a href="/Reglamento">REGLAMENTO</a>
                                                </li>
                                                
                                                <li className="with-megamenu has-menu-child-item position-static">
                                                    <a href="/incentivos">INCENTIVOS</a>
                                                </li>
                                                <li className="with-megamenu has-menu-child-item position-staticnpm">
                                                    <a href="/top-10">TOP 10</a>
                                                </li>
                                                <li className="with-megamenu has-menu-child-item position-static d-none">
                                                    <a href="/champions">CHAMPIONS</a>
                                                </li>

                                                {/* <li className="with-megamenu has-menu-child-item position-static">
                                                    <a href="/recompensa">RECOMPENSAS</a>
                                                </li> */}

                                            </ul>
                                        </nav>
                                </div></>
                            
                        ):(
                            <div></div>
                        )}
                                    
                            
                        </div>

                        <div className="header-right">
                        {authTokenExist ? (
                            <ul className="quick-access">
                            <li className="account-access rbt-user-wrapper right-align-dropdown d-none d-xl-block">
                                <a href="#"><i className="feather-user"></i>{nombre_local}</a>
                                <div className="rbt-user-menu-list-wrapper">
                                    <div className="inner">
                                        <div className="rbt-admin-profile">
                                            <div className="admin-thumbnail">
                                                <img src={`https://plsystem.quarkservers2.com/img/usuarios/${imagen_local}`} alt="Usuario" />
                                            </div>
                                            <div className="admin-info">
                                                <a className="rbt-btn-link color-primary" href="/usuario/perfil">Mi perfil</a>
                                            </div>
                                        </div>
                                        <hr className="mt--10 mb--10" />
                                        <ul className="user-list-wrapper">
                                            <li>
                                                <a href="#" onClick={cerrarSesion}>
                                                    <i className="feather-log-out"></i>
                                                    <span>Cerrar sesión</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            <li className="access-icon rbt-user-wrapper right-align-dropdown d-block d-xl-none">
                                <a className="rbt-round-btn" href="#"><i className="feather-user"></i></a>
                                <div className="rbt-user-menu-list-wrapper">
                                    <div className="inner">
                                        <div className="rbt-admin-profile">
                                            <div className="admin-thumbnail">
                                                <img src="/assets/images/team/avatar.jpg" alt="User Images" />
                                            </div>
                                            <div className="admin-info">
                                                <a className="rbt-btn-link color-primary" href="/usuario/perfil">Mi perfil</a>
                                            </div>
                                        </div>
                                        <hr className="mt--10 mb--10" />
                                        <ul className="user-list-wrapper">
                                            <li>
                                                <a href="#" onClick={cerrarSesion}>
                                                    <i className="feather-log-out"></i>
                                                    <span>Cerrar sesión</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        ) : (
                            <a className="rbt-btn rbt-switch-btn btn-gradient btn-sm hover-transform-none" href="/login" style={{background:'#00b2b5'}}>
                                    <span data-text="INICA SESION" style={{color:'#fff', fontWeight:'700'}}>INGRESAR</span>
                                </a>
                        )}
                        
                            {/* Start Mobile-Menu-Bar */}
                            <div className="mobile-menu-bar d-block d-xl-none">
                                <div className="hamberger">
                                    <button className="hamberger-button rbt-round-btn">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                            {/* Start Mobile-Menu-Bar */}
                        </div>
                    </div>
                </div>
            </div>

            <a className="rbt-close_side_menu" onClick={(e) => { e.preventDefault(); /* Tu lógica aquí */ }}></a>
        </header>

        <div className="popup-mobile-menu">
            <div className="inner-wrapper">
                <div className="inner-top">
                    <div className="content">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/logo/logo.png" alt="Education Logo Images" />
                            </a>
                        </div>
                        <div className="rbt-btn-close">
                            <button className="close-button rbt-round-btn"><i className="feather-x"></i></button>
                        </div>
                    </div>
                    <p className="description">Histudy is a education website template. You can customize all.</p>
                    <ul className="navbar-top-left rbt-information-list justify-content-start">
                        <li>
                            <a href="mailto:hello@example.com"><i className="feather-mail"></i>example@gmail.com</a>
                        </li>
                        <li>
                            <a href="#"><i className="feather-phone"></i>(302) 555-0107</a>
                        </li>
                    </ul>
                </div>

                <nav className="mainmenu-nav">
                    <ul className="mainmenu">
                        <li className="with-megamenu has-menu-child-item position-static">
                            <a href="#">Home <i className="feather-chevron-down"></i></a>
                            {/* Start Mega Menu  */}
                            <div className="rbt-megamenu menu-skin-dark">
                                <div className="wrapper">
                                    <div className="row row--15 home-plesentation-wrapper single-dropdown-menu-presentation">

                                        {/* Start Single Demo  */}
                                        <div className="col-lg-12 col-xl-2 col-xxl-2 col-md-12 col-sm-12 col-12 single-mega-item">
                                            <div className="demo-single">
                                                <div className="inner">
                                                    <div className="thumbnail">
                                                        <a href="01-main-demo.html"><img src="/assets/images/splash/demo/h1.jpg" alt="Demo Images"/></a>
                                                    </div>
                                                    <div className="content">
                                                        <h4 className="title"><a href="01-main-demo.html">Home Demo <span className="btn-icon"><i className="feather-arrow-right"></i></span></a></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="load-demo-btn text-center">
                                        <a className="rbt-btn-link color-white" href="#">Scroll to view more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
                                            </svg></a>
                                    </div>
                                </div>
                            </div>
                            {/* End Mega Menu  */}
                        </li>

                        <li className="with-megamenu has-menu-child-item">
                            <a href="#">Courses <i className="feather-chevron-down"></i></a>
                            {/* Start Mega Menu  */}
                            <div className="rbt-megamenu grid-item-2">
                                <div className="wrapper">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mega-top-banner">
                                                <div className="content">
                                                    <h4 className="title">Developer hub</h4>
                                                    <p className="description">Start building fast, with code samples, key resources and more.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row--15">
                                        <div className="col-lg-12 col-xl-6 col-xxl-6 single-mega-item">
                                            <h3 className="rbt-short-title">Course Layout</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="course-filter-one-toggle.html">Filter One Toggle</a></li>
                                                <li><a href="course-filter-one-open.html">Filter One Open</a></li>
                                                <li><a href="course-filter-two-toggle.html">Filter Two Toggle</a></li>
                                                <li><a href="course-filter-two-open.html">Filter Two Open</a></li>
                                                <li><a href="course-with-tab.html">Course With Tab</a></li>
                                                <li><a href="course-with-tab-two.html">Course With Tab Two</a></li>
                                                <li><a href="course-card-2.html">Course Card Two</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-12 col-xl-6 col-xxl-6 single-mega-item">
                                            <h3 className="rbt-short-title">Course Layout</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="course-card-3.html">Course Card Three</a></li>
                                                <li><a href="course-masonry.html">Course Masonry</a></li>
                                                <li><a href="course-with-sidebar.html">Course With Sidebar</a></li>
                                                <li><a href="course-details.html">Course Details</a></li>
                                                <li><a href="course-details-2.html">Course Details Two</a></li>
                                                <li><a href="lesson.html">Course Lesson <span className="rbt-badge-card">New</span></a></li>
                                                <li><a href="create-course.html">Create Course <span className="rbt-badge-card">New</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <ul className="nav-quick-access">
                                                <li><a href="#"><i className="feather-folder-minus"></i> Quick Start Guide</a></li>
                                                <li><a href="#"><i className="feather-folder-minus"></i> For Open Source</a></li>
                                                <li><a href="#"><i className="feather-folder-minus"></i> API Status</a></li>
                                                <li><a href="#"><i className="feather-folder-minus"></i> Support</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Mega Menu  */}
                        </li>

                        <li className="has-dropdown has-menu-child-item">
                            <a href="#">Dashboard
                                <i className="feather-chevron-down"></i>
                            </a>
                            <ul className="submenu">
                                <li className="has-dropdown"><a href="#">Instructor Dashboard</a>
                                    <ul className="submenu">
                                        <li><a href="instructor-dashboard.html">Dashboard</a></li>
                                        <li><a href="instructor-profile.html">Profile</a></li>
                                        <li><a href="instructor-enrolled-courses.html">Enrolled Courses</a></li>
                                        <li><a href="instructor-wishlist.html">Wishlist</a></li>
                                        <li><a href="instructor-reviews.html">Reviews</a></li>
                                        <li><a href="instructor-my-quiz-attempts.html">My Quiz Attempts</a></li>
                                        <li><a href="instructor-order-history.html">Order History</a></li>
                                        <li><a href="instructor-course.html">My Course</a></li>
                                        <li><a href="instructor-announcements.html">Announcements</a></li>
                                        <li><a href="instructor-quiz-attempts.html">Quiz Attempts</a></li>
                                        <li><a href="instructor-assignments.html">Assignments</a></li>
                                        <li><a href="instructor-settings.html">Settings</a></li>
                                    </ul>
                                </li>
                                <li className="has-dropdown"><a href="#">Student Dashboard</a>
                                    <ul className="submenu">
                                        <li><a href="student-dashboard.html">Dashboard</a></li>
                                        <li><a href="student-profile.html">Profile</a></li>
                                        <li><a href="student-enrolled-courses.html">Enrolled Courses</a></li>
                                        <li><a href="student-wishlist.html">Wishlist</a></li>
                                        <li><a href="student-reviews.html">Reviews</a></li>
                                        <li><a href="student-my-quiz-attempts.html">My Quiz Attempts</a></li>
                                        <li><a href="student-order-history.html">Order History</a></li>
                                        <li><a href="student-settings.html">Settings</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="with-megamenu has-menu-child-item position-static">
                            <a href="#">Pages <i className="feather-chevron-down"></i></a>
                            {/* Start Mega Menu  */}
                            <div className="rbt-megamenu grid-item-4">
                                <div className="wrapper">
                                    <div className="row row--15">
                                        <div className="col-lg-12 col-xl-3 col-xxl-3 single-mega-item">
                                            <h3 className="rbt-short-title">Get Started</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="about-us-01.html">About Us</a></li>
                                                <li><a href="about-us-02.html">About Us 02</a></li>
                                                <li><a href="event-grid.html">Event Grid</a></li>
                                                <li><a href="event-list.html">Event List</a></li>
                                                <li><a href="event-sidebar.html">Event Sidebar</a></li>
                                                <li><a href="event-details.html">Event Details</a></li>
                                                <li><a href="academy-gallery.html">Academy Gallery</a></li>
                                                <li><a href="admission-guide.html">Admission Guide</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-12 col-xl-3 col-xxl-3 single-mega-item">
                                            <h3 className="rbt-short-title">Get Started</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="profile.html">Profile</a></li>
                                                <li><a href="contact.html">Contact Us</a></li>
                                                <li><a href="become-a-teacher.html">Become a Teacher</a></li>
                                                <li><a href="instructor.html">Instructor</a></li>
                                                <li><a href="faqs.html">FAQS</a></li>
                                                <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                                <li><a href="404.html">404 Page</a></li>
                                                <li><a href="maintenance.html">Maintenance</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-12 col-xl-3 col-xxl-3 single-mega-item">
                                            <h3 className="rbt-short-title">Shop Pages</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="shop.html">Shop <span className="rbt-badge-card">Sale Anything</span></a></li>
                                                <li><a href="single-product.html">Single Product</a></li>
                                                <li><a href="cart.html">Cart Page</a></li>
                                                <li><a href="checkout.html">Checkout</a></li>
                                                <li><a href="wishlist.html">Wishlist Page</a></li>
                                                <li><a href="my-account.html">My Acount</a></li>
                                                <li><a href="login.html">Login</a></li>
                                                <li><a href="subscription.html">Subscription</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-12 col-xl-3 col-xxl-3 single-mega-item">
                                            <div className="mega-category-item">
                                                {/* Start Single Category  */}
                                                <div className="nav-category-item">
                                                    <div className="thumbnail">
                                                        <div className="image"><img src="/assets/images/course/category-2.png" alt="Course images"/></div>
                                                        <a href="course-filter-one-toggle.html">
                                                            <span>Online Education</span>
                                                            <i className="feather-chevron-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Single Category  */}

                                                {/* Start Single Category  */}
                                                <div className="nav-category-item">
                                                    <div className="thumbnail">
                                                        <div className="image"><img src="/assets/images/course/category-1.png" alt="Course images"/></div>
                                                        <a href="course-filter-one-toggle.html">
                                                            <span>Language Club</span>
                                                            <i className="feather-chevron-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Single Category  */}

                                                {/* Start Single Category  */}
                                                <div className="nav-category-item">
                                                    <div className="thumbnail">
                                                        <div className="image"><img src="/assets/images/course/category-4.png" alt="Course images"/></div>
                                                        <a href="course-filter-one-toggle.html">
                                                            <span>University Status</span>
                                                            <i className="feather-chevron-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Single Category  */}

                                                {/* Start Single Category  */}
                                                <div className="nav-category-item">
                                                    <div className="thumbnail">
                                                        <a href="course-filter-one-toggle.html">
                                                            <span>Course School</span>
                                                            <i className="feather-chevron-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Single Category  */}

                                                {/* Start Single Category  */}
                                                <div className="nav-category-item">
                                                    <div className="thumbnail">
                                                        <div className="image"><img src="/assets/images/course/category-9.png" alt="Course images"/></div>
                                                        <a href="course-filter-one-toggle.html">
                                                            <span>Academy</span>
                                                            <i className="feather-chevron-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* End Single Category  */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Mega Menu  */}
                        </li>

                        <li className="with-megamenu has-menu-child-item position-static">
                            <a href="#">Elements <i className="feather-chevron-down"></i></a>
                            {/* Start Mega Menu  */}
                            <div className="rbt-megamenu grid-item-3">
                                <div className="wrapper">
                                    <div className="row row--15 single-dropdown-menu-presentation">
                                        <div className="col-lg-4 col-xxl-4 single-mega-item">
                                            <ul className="mega-menu-item">
                                                <li><a href="style-guide.html">Style Guide <span className="rbt-badge-card">Hot</span></a></li>
                                                <li><a href="accordion.html">Accordion</a></li>
                                                <li><a href="advancetab.html">Advance Tab</a></li>
                                                <li><a href="brand.html">Brand</a></li>
                                                <li><a href="button.html">Button</a></li>
                                                <li><a href="badge.html">Badge</a></li>
                                                <li><a href="card.html">Card</a></li>
                                                <li><a href="call-to-action.html">Call To Action</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4 col-xxl-4 single-mega-item">
                                            <ul className="mega-menu-item">
                                                <li><a href="counterup.html">Counter</a></li>
                                                <li><a href="category.html">Categories</a></li>
                                                <li><a href="header.html">Header Style</a></li>
                                                <li><a href="newsletter.html">Newsletter</a></li>
                                                <li><a href="team.html">Team</a></li>
                                                <li><a href="social.html">Social</a></li>
                                                <li><a href="list-style.html">List Style</a></li>
                                                <li><a href="gallery.html">Gallery</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-4 col-xxl-4 single-mega-item">
                                            <ul className="mega-menu-item">
                                                <li><a href="pricing.html">Pricing</a></li>
                                                <li><a href="progressbar.html">Progressbar</a></li>
                                                <li><a href="testimonial.html">Testimonial</a></li>
                                                <li><a href="service.html">Service</a></li>
                                                <li><a href="split.html">Split Area</a></li>
                                                <li><a href="search.html">Search Style</a></li>
                                                <li><a href="instagram.html">Instagram Style</a></li>
                                                <li><a href="#">& More Coming</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="btn-wrapper">
                                                <a className="rbt-btn btn-gradient hover-icon-reverse square btn-xl w-100 text-center mt--30 hover-transform-none" href="#">
                                                    <span className="icon-reverse-wrapper">
                                            <span className="btn-text">Visit Histudy Template</span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Mega Menu  */}
                        </li>

                        <li className="with-megamenu has-menu-child-item position-static">
                            <a href="#">Blog <i className="feather-chevron-down"></i></a>
                            {/* Start Mega Menu  */}
                            <div className="rbt-megamenu grid-item-3">
                                <div className="wrapper">
                                    <div className="row row--15">
                                        <div className="col-lg-12 col-xl-4 col-xxl-4 single-mega-item">
                                            <h3 className="rbt-short-title">Blog Styles</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="blog-list.html">Blog List</a></li>
                                                <li><a href="blog.html">Blog Grid</a></li>
                                                <li><a href="blog-grid-minimal.html">Blog Grid Minimal</a></li>
                                                <li><a href="blog-with-sidebar.html">Blog With Sidebar</a></li>
                                                <li><a href="blog-details.html">Blog Details</a></li>
                                                <li><a href="post-format-standard.html">Post Format Standard</a></li>
                                                <li><a href="post-format-gallery.html">Post Format Gallery</a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-12 col-xl-4 col-xxl-4 single-mega-item">
                                            <h3 className="rbt-short-title">Get Started</h3>
                                            <ul className="mega-menu-item">
                                                <li><a href="post-format-quote.html">Post Format Quote</a></li>
                                                <li><a href="post-format-audio.html">Post Format Audio</a></li>
                                                <li><a href="post-format-video.html">Post Format Video</a></li>
                                                <li><a href="#">Media Under Title <span className="rbt-badge-card">Coming</span></a></li>
                                                <li><a href="#">Sticky Sidebar <span className="rbt-badge-card">Coming</span></a></li>
                                                <li><a href="#">Auto Masonry <span className="rbt-badge-card">Coming</span></a></li>
                                                <li><a href="#">Meta Overlaid <span className="rbt-badge-card">Coming</span></a></li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-12 col-xl-4 col-xxl-4 single-mega-item">
                                            <div className="rbt-ads-wrapper">
                                                <a className="d-block" href="#"><img src="/assets/images/service/mobile-cat.jpg" alt="Education Images"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Mega Menu  */}
                        </li>
                    </ul>
                </nav>

                <div className="mobile-menu-bottom">
                    <div className="rbt-btn-wrapper mb--20">
                        <a className="rbt-btn btn-border-gradient radius-round btn-sm hover-transform-none w-100 justify-content-center text-center" href="#">
                            <span>Enroll Now</span>
                        </a>
                    </div>

                    <div className="social-share-wrapper">
                        <span className="rbt-short-title d-block">Find With Us</span>
                        <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                            <li><a href="https://www.facebook.com/">
                                    <i className="feather-facebook"></i>
                                </a>
                            </li>
                            <li><a href="https://www.twitter.com">
                                    <i className="feather-twitter"></i>
                                </a>
                            </li>
                            <li><a href="https://www.instagram.com/">
                                    <i className="feather-instagram"></i>
                                </a>
                            </li>
                            <li><a href="https://www.linkdin.com/">
                                    <i className="feather-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
  );
}

export function Footer() {
    return (
      <div>
        {/* Start Footer aera */}
        <footer className="rbt-footer footer-style-1">
            <div className="footer-top pb--40">
                <div className="container">
                    <div className="row row--15 mt_dec--30">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                            <div className="footer-widget">
                                <div className="logo">
                                        <img src="/assets/images/micrositio/PLe_FooterLogo_2024.png" alt="Edu-cause" />
                                    
                                </div>

                                <p className="description mt--20">P-Learning es un programa diseñado exclusivamente para la fuerza de ventas de los distribuidores autorizados Panduit.
                                </p>
                            </div>
                        </div>

                        <div className="offset-lg-1 col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* End Footer aera */}
        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0" />
            </div>
        </div>
        {/* Start Copyright Area  */}
        <div className="copyright-area copyright-style-1 ptb--20">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
                        <p className="rbt-link-hover text-center text-lg-start">2024 © Todos los derechos reservados.</p>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
                        <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10" style={{listStyle:'none'}}>
                            <li><a href="https://wa.me/+525624560091" target="_blank">Contacto </a></li>
                            <li><a href="/TyC">Términos y Condiciones </a></li>
                            <li><a href="/aviso-privacidad">Aviso de Privacidad </a></li>
                            <li><a href="/login">Login</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* End Copyright Area  */}
        <div className="rbt-progress-parent">
            <svg className="rbt-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
        </div>

      </div>
    );
  }

export default Header;
