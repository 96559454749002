import React, { useState, useEffect } from "react";
import { format } from 'date-fns';
import { SeccionUno, SeccionDos, MultiSteps1 } from "../components/sections";
import Swal from 'sweetalert2';
import BannerWhatsapp from "../components/sections";
import { Container, Row, Col, Carousel, Card, Button, ProgressBar, Modal, Form,  FormCheck  } from 'react-bootstrap';

import {   BrowserRouter as Router,
    useHistory, useParams } from 'react-router-dom';
  import { useVariablesGlobales } from '../globales/VariablesGlobales';
  import axios from 'axios';
  import ReactPlayer from 'react-player';
  axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;

export function Temporada() {
    /**
     * Variables globales y datos
     */
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [listadoSesiones, setListadoSesiones] = useState(null);
    const [datosTerminos, setDatosTerminos] = useState(null);
    

    /**
     * Variables de interactividad
     */
    const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);


    /**
     * Funciones de interactividad
     */

    const handleCheckboxChange = (event) => {
     setIsChecked(event.target.checked);
    };
      
    // Función para cerrar el modal
    const handleClose = () => setShow(false);

    // Función para manejar el clic en el botón Aceptar
    const handleAccept = () => {
        localStorage.setItem('aceptados_terminos', 'true'); // Guarda la variable local
        setShow(false); // Cierra el modal
    };
    
    /**
     * Funciones de UseEffect
     */
    useEffect(() => {
        // llamada para los datos de la temporada
        axios.get('https://plsystem.quarkservers2.com/api/datos_temporada', {
            params: {
              id: cuentaPL
            }
          })
          .then(function (response) {
            //console.log(response);
            setDatosTemporada(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    
      }, []);

    // llamada para el listado de sesiones
    useEffect(()=>{
        // llamada para el listado de sesiones
        if(datosTemporada){
          axios.get('https://plsystem.quarkservers2.com/api/lista_sesiones', {
            params: {
              id_temporada: datosTemporada.id
            }
          })
          .then(function (response) {
            //console.log(response);
            setListadoSesiones(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
        }
          
      }, [datosTemporada]);



    // Función para mostrar el modal automáticamente al cargar la página
    useEffect(() => {
        const aceptadosTerminos = localStorage.getItem('aceptados_terminos');
        if (!aceptadosTerminos) {
        setShow(true);
        }
    }, []);

    // Esta función llama el contenido de los términos y condiciones
    useEffect(() => {
        if(loading){
            
            axios.get('https://plsystem.quarkservers2.com/api/datos_publicacion', {
                params: {
                id: 1
                }
            })
            .then(function (response) {
                console.log(response.data);
                setDatosTerminos(response.data);
                setLoading(false);
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
                setLoading(false);
            });
        }
        
    }, [loading]); // Solo depende de id
  

  // vista de carga verifica que los datos se hayan cargado
  if (!datosTemporada) {
    //console.log('Renderiza mensaje de cargando');
    return (
        <div className='contenedor-principal'>
            <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                <div className="container">
                    {/* Start Card Area */}
                    <div className="row row-15">
                        <div className="col-lg-12 text-center p-4">
                            <h5>Cargando datos...</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  // Verifico la fecha
  const hoy = new Date();
  const fecha_inicio = new Date(datosTemporada.fecha_inicio);
  if(hoy <= fecha_inicio && datosTemporada){
    console.log(datosTemporada);
    return (
        <div className='contenedor-principal'>
            <div className="">
                <div className="container">
                    {/* Start Card Area */}
                    <div className="row row-15">
                        <div className="col-lg-12 text-center p-4">
                            <h5>{datosTemporada.titulo_landing}</h5>
                            {datosTemporada.mensaje_landing}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  if(hoy >= fecha_inicio && datosTerminos){
    return (
            <div className='contenedor-principal'>

                <div className="rbt-page-banner-wrapper">
                    {/* Start Banner BG Image  */}
                    <div className="rbt-banner-image"></div>
                    {/* End Banner BG Image  */}
                    <div className="rbt-banner-content">

                        {/* Start Banner Content Top  */}
                        <div className="rbt-banner-content-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className=" title-wrapper">
                                            <h1 className="title mb--0 text-white">Temporadas {datosTemporada.nombre}</h1>
                                        </div>

                                        <p className="description" style={{color:'#fff', fontWeight:'500'}}> {datosTemporada.descripcion} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Banner Content Top  */}
                        {/* Start Course Top  */}
                        <div className="rbt-course-top-wrapper mt--40 mt_sm--20">
                            <div className="container">
                                {/* <div className="row g-5 align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                    </div>

                                    <div className="col-lg-7 col-md-12">
                                        <div className="rbt-sorting-list d-flex flex-wrap align-items-center justify-content-start justify-content-lg-end">
                                            <div className="rbt-short-item">
                                                <form action="#" className="rbt-search-style me-0">
                                                    <input type="text" placeholder="Buscar Sesiones" />
                                                    <button type="submit" className="rbt-search-btn rbt-round-btn">
                                                        <i className="feather-search"></i>
                                                    </button>
                                                </form>
                                            </div>

                                            <div className="rbt-short-item">
                                                <div className="view-more-btn text-start text-sm-end">
                                                    <button className="discover-filter-button discover-filter-activation rbt-btn btn-white btn-md radius-round">Filtro<i className="feather-filter"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Start Filter Toggle  */}
                                <div className="default-exp-wrapper default-exp-expand d-none">
                                    <div className="filter-inner">
                                        <div className="filter-select-option">
                                            <div className="filter-select rbt-modern-select">
                                                <span className="select-label d-block text-white">Temporada</span>
                                                <div className="dropdown bootstrap-select">
                                                <select className="">
                                                    <option>Default</option>
                                                    <option>Latest</option>
                                                    <option>Popularity</option>
                                                    <option>Trending</option>
                                                    <option>Price: low to high</option>
                                                    <option>Price: high to low</option>
                                                </select>
                                                <button type="button" tabIndex="-1" className="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="Default"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Default</div></div> </div></button>
                                                <div className="dropdown-menu "><div className="inner show" role="listbox" id="bs-select-1" tabIndex="-1"><ul className="dropdown-menu inner show" role="presentation"></ul></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Filter Toggle  */}
                            </div>
                        </div>
                        {/* End Course Top  */}
                    </div>
                </div>

                <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                    <div className="container">
                        {/* Start Card Area */}
                        <div className="row row-15">
                            <div className="col-lg-12">
                                <div className="mesonry-list grid-metro2">
                                    <div className="resizer"></div>
                                    {listadoSesiones && listadoSesiones.map((sesion, index) => {
                                        // Calcula el índice de la clase cat
                                        const catIndex = index % 4 + 1;
                                        // Crea el nombre de la clase dinámicamente
                                        const catClass = `cat--${catIndex}`;
                                        const fechaEstreno = new Date (sesion.fecha_publicacion);
                                        const fechaFormateada = format (fechaEstreno, 'dd/MM/yyyy');
                                        const hoy = new Date(); // Obtiene la fecha actual
                                        return (
                                            <div className={`maso-item ${catClass}`} key={index}>
                                                <div className="rbt-card variation-01 rbt-hover card-list-2">
                                                    <div className="rbt-card-img">
                                                        <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>
                                                        <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${sesion.imagen}`} alt="Card image" />


                                                        </a>
                                                    </div>
                                                    <div className="rbt-card-body">
                                                        <h4 className="rbt-card-title">
                                                            <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>{sesion.titulo}</a>
                                                        </h4>
                                                        <ul className="rbt-meta">
                                                            <li><i className="feather-clock"></i>{sesion.duracion_aproximada}</li>
                                                            <li className="rbt-card-text"><i className="feather-calendar"></i>Estreno: {fechaFormateada}</li>
                                                        </ul>
                                                        <div className="rbt-author-meta mb--10">
                                                            <div className="rbt-avater">
                                                                <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>
                                                                    <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${sesion.imagen_instructor}`} alt="Sophia Jaymes" />
                                                                </a>
                                                            </div>
                                                            <div className="rbt-author-info">
                                                                Experto {sesion.nombre_instructor}
                                                            </div> <br/>
                                                        </div>
                                                        {fechaEstreno > hoy ? (<a className="transparent-button" disabled style={{ maxWidth: '100%', marginLeft: 'auto'}} href='#'>Próximamente</a>):(<a className="rbt-btn btn-sm" style={{ maxWidth: '40%', marginLeft: 'auto'}} href={`/sesion/${sesion.id}`}>Ver sesión</a>) }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                                {/* End Card Area */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <SeccionDos /> */}
                {/**Pop up de terminos y condiciones */}
                <Modal className="popup-notificaciones" show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: datosTerminos.contenido }} />
                    </Modal.Body>

                    
                    <Modal.Footer>
                        <div className="row w-100">
                            <div className="col-2">
                            <Button variant="secondary" className="rbt-btn rbt-switch-btn" onClick={handleClose}>
                                Cerrar
                            </Button>
                            </div>
                            <div className="col-10 d-flex justify-content-end">
                            <Form.Check
                                className="me-4 mt-4"
                                type={'checkbox'}
                                id={`default-checkbox`}
                                label={`He leido y acepto los términos y condiciones`}
                                onChange={handleCheckboxChange}
                            />

                            <Button variant="primary" className="rbt-btn btn-gradient rbt-switch-btn" onClick={handleAccept} disabled={!isChecked}>
                                Aceptar
                            </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                    </Modal>
            </div>
    );
  }
}

export function TemporadaEspecifica() {
    /**
     * Variables globales y datos
     */
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { id_temporada } = useParams();
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [listadoSesiones, setListadoSesiones] = useState(null);
    /**
     * Variables de interactividad
     */
    /**
     * Funciones de interactividad
     */
    /**
     * Funciones de UseEffect
     */
    
    // llamada para los datos de la temporada
    useEffect(() => {
    
        axios.get('https://plsystem.quarkservers2.com/api/temporada_y_sesiones', {
        params: {
            id: id_temporada
        }
        })
        .then(function (response) {
        //console.log(response);
        setDatosTemporada(response.data.temporada);
        setListadoSesiones(response.data.sesiones);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, []);
  

    if (!datosTemporada) {
        //console.log('Renderiza mensaje de cargando');
        return (
            <div className='contenedor-principal'>
                <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                    <div className="container">
                        {/* Start Card Area */}
                        <div className="row row-15">
                            <div className="col-lg-12 text-center p-4">
                                <h5>Cargando datos...</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
  // Verifico la fecha
  if(datosTemporada){
    return (
            <div className='contenedor-principal'>
                <div className="rbt-page-banner-wrapper">
                    {/* Start Banner BG Image  */}
                    <div className="rbt-banner-image"></div>
                    {/* End Banner BG Image  */}
                    <div className="rbt-banner-content">
                        {/* Start Banner Content Top  */}
                        <div className="rbt-banner-content-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className=" title-wrapper">
                                            <h1 className="title mb--0 text-white">Temporada {datosTemporada.nombre}</h1>
                                        </div>

                                        <p className="description" style={{color:'#fff', fontWeight:'500'}}> {datosTemporada.descripcion} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Banner Content Top  */}
                        {/* Start Course Top  */}
                        <div className="rbt-course-top-wrapper mt--40 mt_sm--20">
                            <div className="container">
                                {/* <div className="row g-5 align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                    </div>

                                    <div className="col-lg-7 col-md-12">
                                        <div className="rbt-sorting-list d-flex flex-wrap align-items-center justify-content-start justify-content-lg-end">
                                            <div className="rbt-short-item">
                                                <form action="#" className="rbt-search-style me-0">
                                                    <input type="text" placeholder="Buscar Sesiones" />
                                                    <button type="submit" className="rbt-search-btn rbt-round-btn">
                                                        <i className="feather-search"></i>
                                                    </button>
                                                </form>
                                            </div>

                                            <div className="rbt-short-item">
                                                <div className="view-more-btn text-start text-sm-end">
                                                    <button className="discover-filter-button discover-filter-activation rbt-btn btn-white btn-md radius-round">Filtro<i className="feather-filter"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Start Filter Toggle  */}
                                <div className="default-exp-wrapper default-exp-expand d-none">
                                    <div className="filter-inner">
                                        <div className="filter-select-option">
                                            <div className="filter-select rbt-modern-select">
                                                <span className="select-label d-block text-white">Temporada</span>
                                                <div className="dropdown bootstrap-select">
                                                <select className="">
                                                    <option>Default</option>
                                                    <option>Latest</option>
                                                    <option>Popularity</option>
                                                    <option>Trending</option>
                                                    <option>Price: low to high</option>
                                                    <option>Price: high to low</option>
                                                </select>
                                                <button type="button" tabIndex="-1" className="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="Default"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Default</div></div> </div></button>
                                                <div className="dropdown-menu "><div className="inner show" role="listbox" id="bs-select-1" tabIndex="-1"><ul className="dropdown-menu inner show" role="presentation"></ul></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Filter Toggle  */}
                            </div>
                        </div>
                        {/* End Course Top  */}
                    </div>
                </div>

                <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                    <div className="container">
                        {/* Start Card Area */}
                        <div className="row row-15">
                            <div className="col-lg-12">
                                <div className="mesonry-list grid-metro2">
                                    <div className="resizer"></div>
                                    {listadoSesiones && listadoSesiones.map((sesion, index) => {
                                        // Calcula el índice de la clase cat
                                        const catIndex = index % 4 + 1;
                                        // Crea el nombre de la clase dinámicamente
                                        const catClass = `cat--${catIndex}`;
                                        const fechaEstreno = new Date (sesion.fecha_publicacion);
                                        const fechaFormateada = format (fechaEstreno, 'dd/MM/yyyy');
                                        const hoy = new Date(); // Obtiene la fecha actual
                                        return (
                                            <div className={`maso-item ${catClass}`} key={index}>
                                                <div className="rbt-card variation-01 rbt-hover card-list-2">
                                                    <div className="rbt-card-img">
                                                        <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>
                                                        <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${sesion.imagen}`} alt="Card image" />


                                                        </a>
                                                    </div>
                                                    <div className="rbt-card-body">
                                                        <h4 className="rbt-card-title">
                                                            <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>{sesion.titulo}</a>
                                                        </h4>
                                                        <ul className="rbt-meta">
                                                            <li><i className="feather-clock"></i>{sesion.duracion_aproximada}</li>
                                                            <li className="rbt-card-text"><i className="feather-calendar"></i>Estreno: {fechaFormateada}</li>
                                                        </ul>
                                                        <div className="rbt-author-meta mb--10">
                                                            <div className="rbt-avater">
                                                                <a href={fechaEstreno > hoy ? '#' : `/sesion/${sesion.id}`}>
                                                                    <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${sesion.imagen_instructor}`} alt="Sophia Jaymes" />
                                                                </a>
                                                            </div>
                                                            <div className="rbt-author-info">
                                                                Experto {sesion.nombre_instructor}
                                                            </div> <br/>
                                                        </div>
                                                        {fechaEstreno > hoy ? (<a className="transparent-button" disabled style={{ maxWidth: '100%', marginLeft: 'auto'}} href='#'>Próximamente</a>):(<a className="rbt-btn btn-sm" style={{ maxWidth: '40%', marginLeft: 'auto'}} href={`/sesion/${sesion.id}`}>Ver sesión</a>) }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                                {/* End Card Area */}
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
    );
  }
}

export function Sesion() {

    /**
     * Variables globales y datos
     */
    const [datosSesion, setDatosSesion] = useState(null);
    const [listaVideos, setListaVideos] = useState(null);
    const [cantidadVideos, setCantidadVideos] = useState(0);
    const [videoSeleccionado, setVideoSeleccionado] = useState(0);
    const [maxVideoSeleccionado, setMaxVideoSeleccionado] = useState(0);
    const { id } = useParams();
    const [listadoSesiones, setListadoSesiones] = useState(null);
    const [listadoPreguntas, setListadoPreguntas] = useState(null);
    const [numeroPreguntas, setNumeroPreguntas] = useState(0)
    const [listadoDudas, setListadoDudas] = useState(null);
    const [listadoAnexos, setListadoAnexos] = useState(null);
    const [listadoRespuestas, setListadoRespuestas] = useState(null);
    const [puntajeTotal, setPuntajeTotal] = useState(0);
    const [datosVisualizacion, setDatosVisualizacion] = useState(null);
    /**
     * Variables de interactividad
     */
   
    const [sesionVista, setSesionVista] = useState(false);
    const [mostrarVideo, setMostrarVideo] = useState(true);
    const [estadoDelVideo, setEstadoDelVideo] = useState('Pendiente');
    const [mostrarEvaluacion, setMostrarEvaluacion] = useState(false);
    const [mostrarResultados, setMostrarResultados] = useState(false);
    const [evaluacionCompleta, setEvaluacionCompleta] = useState(false);
    const [duda, setDuda] = useState('');
    const [recargarDatos, setRecargarDatos] = useState(false);
    const [mostrarPuntajes, setMostrarPuntajes] = useState(false);
    const [puntajeMostrar, setPuntajeMostrar] = useState(0);
    //Preguntas y respuestas
    const [selectedOptions, setSelectedOptions] = useState({});
    /**
     * Funciones de interactividad
     */

    const escribirDuda = (event) => { setDuda(event.target.value); };

    const guardarDuda =  async () => {
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        // Aquí puedes realizar la lógica para guardar la duda
        console.log('Guardando la duda:', duda);
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_duda', {
              id_usuario: id_local,
              id_sesion: datosSesion.id,
              duda: duda,
            });
            cargaListaPreguntasRespuestas();
            setDuda('');
            
          } catch (error) {
            console.error('Error al enviar la solicitud:', error);
          } 
        
    };

    const handleVideoChange = (index) => {
        
        setVideoSeleccionado(index);
         // Actualizar el valor máximo de video seleccionado si es mayor que el actual.
        if (index > maxVideoSeleccionado) {
            setMaxVideoSeleccionado(index);
        }
        //console.log(listaVideos[index]);
        setMostrarVideo(true); // Esto muestra el video cuando se selecciona
    };

     // Función que encuentra el índice más alto no nulo en listaVideos
     const getUltimoIndiceValido = () => {
        return listaVideos.reduce((maxIndex, video, index) => {
            return video !== null ? index : maxIndex;
        }, -1); // Empezamos con -1 por si no hay videos válidos
    };

    const handleOnEnded = () => {
        const ultimoIndiceValido = getUltimoIndiceValido();

        if (videoSeleccionado === ultimoIndiceValido) {
            // Si estamos en el último video válido, llamamos a handleEnded
            handleEnded();
        } else {
            // Si no es el último video, llamamos a handleVideoRegister
            handleVideoRegister();
        }
    };

    // Funciones de interacción del formulario
    const totalSteps = numeroPreguntas;
    const [step, setStep] = useState(0);

    const handleGroupChange = (group, selectedValue) => {
        setSelectedOptions({
        ...selectedOptions,
        [group]: selectedValue,
        });
        console.log(selectedOptions);
        
    };

    const handlePrevious = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleNext = () => {
        if (step === 0) {
            setStep(step + 1);
        } else if (step >= totalSteps - 1) {
            // Aquí puedes llamar a tu función para manejar las respuestas
            guardarRespuestas();
        } else {
            setStep(step + 1);
        }
    };

    const guardarRespuestas = async () => {
        const respuestas = selectedOptions;
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_respuestas_evaluacion', {
              id_usuario: id_local,
              id_sesion: datosSesion.id,
              respuestas: respuestas,
            });
            //console.log('se supone que se envió la evaluacion');
            console.log(response);
            setEvaluacionCompleta(true); 
            setMostrarResultados(true);
            setMostrarEvaluacion(false);
            cargaListaPreguntasRespuestas();
            setRecargarDatos(!recargarDatos)
            
          } catch (error) {
            console.error('Error al enviar la solicitud:', error);
          } 
    };

      const handlePlay = () => {
        console.log('El video se ha iniciado');
        // Aquí puedes agregar el código que deseas ejecutar cuando el video se inicie
      };

      const handleEnded = async (event) => {
        console.log('El video se ha terminado');
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        const maxReintentos = 3;
        const intervaloReintento = 2000; // 2 segundos

        const registrarVisualizacion = async (intentos) => {
            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_visualizacion', {
                    id_usuario: id_local,
                    id_sesion: datosSesion.id,
                    index_video: videoSeleccionado
                });
                console.log(response.data);
                return response.data;
            } catch (error) {
                if (intentos > 0) {
                    console.warn(`Intento fallido. Reintentando en ${intervaloReintento / 1000} segundos...`);
                    await new Promise(resolve => setTimeout(resolve, intervaloReintento));
                    return registrarVisualizacion(intentos - 1);
                } else {
                    throw error;
                }
            }
        };

        try {
            const resultado = await registrarVisualizacion(maxReintentos);
            setSesionVista(true);
            if (resultado.success) {
                setMostrarEvaluacion(true);
                setMostrarVideo(false);
                setMostrarPuntajes(true);
                setPuntajeMostrar(resultado.puntaje);
                setEstadoDelVideo('Completado');
            } else {
                setMostrarVideo(false);
                setMostrarEvaluacion(true);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud tras varios intentos:', error);
            // Manejo del error en caso de fallar todos los intentos
            setMostrarVideo(false);
            setMostrarEvaluacion(false);
        }
        
      };

      const handleVideoRegister = async (event) => {
        console.log('Registrar avance');
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        const maxReintentos = 3;
        const intervaloReintento = 2000; // 2 segundos
        

        const registrarVisualizacion = async (intentos) => {
            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_avance', {
                    id_usuario: id_local,
                    id_sesion: datosSesion.id,
                    index_video: videoSeleccionado
                });
                console.log(response.data);
                return response.data;
            } catch (error) {
                console.log('Falló');
                if (intentos > 0) {
                    console.warn(`Intento fallido. Reintentando en ${intervaloReintento / 1000} segundos...`);
                    await new Promise(resolve => setTimeout(resolve, intervaloReintento));
                    return registrarVisualizacion(intentos - 1);
                } else {
                    throw error;
                }
            }
        };

        try {
            const resultado = await registrarVisualizacion(maxReintentos);
            const siguienteIndice = listaVideos.findIndex((video, index) => index > videoSeleccionado && video !== null);
            if(resultado.success){
                setPuntajeMostrar(resultado.puntaje)
                setMostrarPuntajes(true);
                setSesionVista(true);
                setEstadoDelVideo('En progreso');
            }
            
            
            
            if (siguienteIndice !== -1) {
                // Si se encuentra un índice válido, cambiar al siguiente video
                handleVideoChange(siguienteIndice);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud tras varios intentos:', error);
            // Manejo del error en caso de fallar todos los intentos
            setMostrarVideo(true);
            setMostrarEvaluacion(false);
        }
        
      };

    const cargaListaPreguntasRespuestas = async () => {
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));

          // Llamada para el listado de preguntas y respuestas
          axios.get('https://plsystem.quarkservers2.com/api/preguntas_y_respuestas_sesion', {
            params: {
              id_sesion: datosSesion.id,
              id_usuario: id_local
            }
          })
          .then(function (response) {
            //console.log(response.data);
            setListadoPreguntas(response.data.preguntas);
            setListadoRespuestas(response.data.respuestas);
            if (response.data.respuestas.length > 0) {
                setMostrarResultados(true);
                setMostrarEvaluacion(false);
                setEvaluacionCompleta(true); 
                setEstadoDelVideo('Completado');
            }
            
            let conteo_puntos = 0;
            response.data.respuestas.forEach( respuesta =>{
                if(respuesta.respuesta_correcta=='correcto'){
                    conteo_puntos+= parseInt(respuesta.puntaje, 10);
                }
            });
            setPuntajeTotal(conteo_puntos);
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    }
    
    /**
     * Funciones de UseEffect
     */
    // Obtengo los datos de la sesión
    useEffect(() => {
        if (!id) {
            // No hagas nada si id no está definido
            return;
        }
        
        // Realiza la solicitud al servidor solo si id es válido
        axios.get('https://plsystem.quarkservers2.com/api/full_datos_sesion', {
            params: {
            id: id
            }
        })
        .then(function (response) {
            //console.log(response.data.dudas);
            setDatosSesion(response.data.sesion);
            const videos = [
                response.data.sesion.video_1,
                response.data.sesion.video_2,
                response.data.sesion.video_3,
                response.data.sesion.video_4,
                response.data.sesion.video_5,
            ];
            setListaVideos(videos);

            const conteoVideos = videos.filter(video => video !== null).length;
            setCantidadVideos(conteoVideos);
            setListadoSesiones(response.data.sesiones_pendientes);
            setListadoDudas(response.data.dudas);
            setListadoAnexos(response.data.anexos);
            setMostrarPuntajes(response.data.mostrar_puntajes);
        })
        .catch(function (error) {
            console.log('El error fue:' + error);
        });
    }, [id]); // Solo depende de id
    
    // Este use effect hace el chequeo de visualización
    useEffect(()=>{
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        // llamada para el listado de sesiones pendientes
        if(datosSesion){

          // Reviso si ya había visto la sesión
          axios.get('https://plsystem.quarkservers2.com/api/checar_full_visualizacion', {
            params: {
                id_usuario: id_local,
                id_sesion: datosSesion.id
            }
          })
          .then(function (response) {
            //console.log(response.data);
            const data = response.data;
            const isDataEmpty = !data || Object.keys(data).length === 0;

            setDatosVisualizacion(data);

            // Definir las variables para videos y fechas
            const videos = [data.video_1, data.video_2, data.video_3, data.video_4, data.video_5];
            const fechas = [
            data.fecha_video_1,
            data.fecha_video_2,
            data.fecha_video_3,
            data.fecha_video_4,
            data.fecha_video_5,
            ];

            let videoSeleccionadoIndex = -1;

            // Revisar el primer video sin fecha y que no sea nulo
            for (let i = 0; i < videos.length; i++) {
            if (videos[i] !== null && !fechas[i]) {
                videoSeleccionadoIndex = i; // Guardar el primer video sin fecha y no nulo
                break; // Nos detenemos al encontrar el primer video sin fecha
            }
            }

            if (videoSeleccionadoIndex !== -1) {
            // Si encontramos un video sin fecha, lo seleccionamos
            setVideoSeleccionado(videoSeleccionadoIndex);
            setMaxVideoSeleccionado(videoSeleccionadoIndex);
            } else {
            // Si todos los videos tienen fecha o son nulos, seleccionamos el primer video con fecha
            const ultimoVistoIndex = fechas.findIndex(fecha => fecha !== null);
            setVideoSeleccionado(ultimoVistoIndex !== -1 ? ultimoVistoIndex : 0); 
            setMaxVideoSeleccionado(ultimoVistoIndex !== -1 ? ultimoVistoIndex : 0);
            }

            if (isDataEmpty) {
                setMostrarVideo(true);
                setSesionVista(false);
                setMostrarEvaluacion(false);
            } else {
                if(response.data.fecha_ultimo_video){
                    setMostrarVideo(false);
                    setSesionVista(true);
                    setMostrarEvaluacion(true);
                    setMostrarResultados(false);
                    
                }else{
                    setMostrarVideo(true);
                    setSesionVista(true);
                    setMostrarEvaluacion(false);
                }
                
            }
            console.log(data.puntaje);
            setPuntajeMostrar(data.puntaje);
            cargaListaPreguntasRespuestas();
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });

          

        }
          
      }, [datosSesion, recargarDatos]);

    
    if(datosSesion&&listadoPreguntas&&listadoRespuestas){
        const hoy = new Date();
        const fecha_inicio = new Date(datosSesion.fecha_publicacion);
        
        if(hoy >= fecha_inicio ){
            return (
                <div className='contenedor-principal'>
                    <div className="rbt-page-banner-wrapper">
                        {/* Start Banner BG Image  */}
                        <div className="rbt-banner-image"></div>
                        {/* End Banner BG Image  */}
                        <div className="rbt-banner-content">
                            {/* Start Banner Content Top  */}
                            <div className="rbt-banner-content-top rbt-breadcrumb-style-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2">
                                            <div className="content text-center">
                                                {/* Se decidio coaultar por que se utilizara en un futuro  */}
                                                {/* <div className="d-flex align-items-center flex-wrap justify-content-center mb--15 rbt-course-details-feature">
                                                    <div className="feature-sin best-seller-badge">
                                                        <span className="rbt-badge-2">
                                                                <span className="image"><img src="assets/images/icons/card-icon-1.png" alt="Best Seller Icon" /></span> Bestseller
                                                        </span>
                                                    </div>
                                                    <div className="feature-sin rating">
                                                        <a href="#">4.8</a>
                                                        <a href="#"><i className="fa fa-star"></i></a>
                                                        <a href="#"><i className="fa fa-star"></i></a>
                                                        <a href="#"><i className="fa fa-star"></i></a>
                                                        <a href="#"><i className="fa fa-star"></i></a>
                                                        <a href="#"><i className="fa fa-star"></i></a>
                                                    </div>
                                                    <div className="feature-sin total-rating">
                                                        <a className="rbt-badge-4" href="#">215,475 rating</a>
                                                    </div>
                                                    <div className="feature-sin total-student">
                                                        <span>616,029 students</span>
                                                    </div>
                                                </div> */}
                                                <h2 className="title" style={{color:'#fff'}}>{datosSesion.titulo}</h2>
    
                                                <div className="rbt-author-meta mb--20 justify-content-center" style={{color:'#fff'}}>
                                                    <div className="rbt-avater">
                                                        <a href="#">
                                                            <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${datosSesion.imagen_instructor}`} alt="Sophia Jaymes" />
                                                        </a>
                                                    </div>
                                                    <div className="rbt-author-info" style={{color:'#fff'}}>
                                                        Experto {datosSesion.nombre_instructor}
                                                    </div>
                                                </div>
    
                                            
    
                                                    <div style={{color:'#fff'}} dangerouslySetInnerHTML={{ __html: datosSesion.contenido }} />
    
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Banner Content Top  */}
                        </div>
                    </div>
    
                    <div className="rbt-section-overlayping-top rbt-section-gapBottom">
                        <div className="inner">
                            <div className="container">
                                <div className="col-lg-12">
                                {mostrarVideo && videoSeleccionado !== null ? (
                                    <>
                                        <ReactPlayer
                                            url={`https://vimeo.com/${listaVideos[videoSeleccionado]}`}
                                            controls={true}
                                            width="100%"
                                            height="800px"
                                            onPlay={() => console.log("Playing video")}
                                            onEnded={handleOnEnded}
                                        />

                                        {/* Se mantiene el contenedor de navegación */}
                                        {cantidadVideos > 1 && (
                                            <div className="rbt-inner-onepage-navigation sticky-top mt--30">
                                                <nav className="mainmenu-nav onepagenav">
                                                <ul className="mainmenu">
                                                    {listaVideos.map((video, index) => {
                                                        if (!video) return null; // Verifica si el video existe

                                                        const esActivo = index <= maxVideoSeleccionado;
                                                        const esSeleccionado = index === videoSeleccionado;

                                                        return (
                                                        <li key={index} className={`${esSeleccionado ? 'current' : ''} ${!esActivo ? 'disabled' : ''}`}>
                                                            <a
                                                            onClick={() => esActivo && handleVideoChange(index)} // Solo llama a la función si es activo
                                                            className={!esActivo ? 'link-disabled' : ''}
                                                            style={{ pointerEvents: !esActivo ? 'none' : 'auto', cursor: !esActivo ? 'not-allowed' : 'pointer' }}
                                                            >
                                                            Video {index + 1}
                                                            </a>
                                                        </li>
                                                        );
                                                    })}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                        </>
                                    ) : null}

                                    {mostrarEvaluacion ? (
                                        listadoPreguntas.length === 0 ? (
                                            <div className="d-flex justify-content-between p-4 mt--30" style={{ backgroundColor: '#ddd'}}>
                                                    <p className="mb--0" style={{fontWeight:'700', color:'#000', fontSize:'20px', alignContent:'center'}}> Este video corresponde a una sesión especial y no incluye evaluación</p>
                                                <div className="">
                                                <button
                                                    className={`rbt-btn btn-gradient icon-hover radius-round btn-sm`}
                                                    onClick={() => {setMostrarVideo(true); setMostrarResultados(false); setMostrarEvaluacion(false); setVideoSeleccionado(0)}}
                                                >
                                                    Volver a vídeo
                                                </button>
                                                </div>
                                                
                                            </div>
                                        ) : (
                                            <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30">
                                                <div className="rbt-course-feature-inner">
                                                    <div className="section-title">
                                                        <h4 className="rbt-title-style-3">Evaluación</h4>
                                                    </div>
                                                    <div className='progress-bar'>
                                                        <div className='progress' style={{ width: step === 0 ? '33%' : '80%' }}></div>
                                                    </div>
                                                    <div className='container'>
                                                        {listadoPreguntas.map((pregunta, index) => {
                                                            const respuesta = listadoRespuestas.find(respuesta => respuesta.id_pregunta === pregunta.id);
                                                            return (
                                                                <div key={index} className={index === step ? "" : "d-none"}>
                                                                    <RadioButtonGroupTrivia
                                                                        groupName={`pregunta-${pregunta.id}`}
                                                                        id_pregunta={pregunta.id}
                                                                        pregunta={pregunta.pregunta}
                                                                        options={[
                                                                            ...(pregunta.respuesta_a ? [{ label: pregunta.respuesta_a, value: 'A' }] : []),
                                                                            ...(pregunta.respuesta_b ? [{ label: pregunta.respuesta_b, value: 'B' }] : []),
                                                                            ...(pregunta.respuesta_c ? [{ label: pregunta.respuesta_c, value: 'C' }] : []),
                                                                            ...(pregunta.respuesta_d ? [{ label: pregunta.respuesta_d, value: 'D' }] : []),
                                                                        ]}
                                                                        onChange={handleGroupChange}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className='btn-container'>
                                                        <button className="rbt-btn btn-gradient icon-hover" disabled={step === 0} onClick={handlePrevious}>
                                                            <span className="btn-text">Anterior</span>
                                                            <span className="btn-icon"><i className="feather-arrow-left"></i></span>
                                                        </button>
                                                        {step === 1 ? (
                                                            <button className="rbt-btn btn-gradient icon-hover" onClick={guardarRespuestas}>
                                                                <span className="btn-text">Guardar respuestas</span>
                                                                <span className="btn-icon"><i className="feather-save"></i></span>
                                                            </button>
                                                        ) : (
                                                            <button className="rbt-btn btn-gradient icon-hover" onClick={handleNext}>
                                                                <span className="btn-text">Siguiente</span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : null}
    
                                    {mostrarResultados ?  (
                                        <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30">
                                             <div className="rbt-course-feature-inner">
                                                 <div className="section-title">
                                                     <h4 className="rbt-title-style-3">Evaluación completa</h4>
                                                 </div>  
                                                 <div className='progress-bar'>
                                                     <div className='progress' style={{width:'100%'}}></div>
                                                 </div>
                                             
                                                 <div className="row">
                                                 {listadoPreguntas && listadoPreguntas.map((pregunta, index) => {
                                                     const respuesta = listadoRespuestas.find(respuesta => respuesta.id_pregunta === pregunta.id);
                                                     if(respuesta){
                                                         
                                                         //console.log('Respuesta: '+respuesta.respuesta_correcta);
                                                         let clase_letra = ['','','',''];
                                                         let peso_letra = ['normal','normal','normal','normal'];
                                                         let color_letra =  ['#000','#000','#000','#000'];
                                                         let icono = ['d-none','d-none','d-none','d-none'];
                                                         switch (pregunta.respuesta_correcta) {
                                                             case 'A':
                                                                 clase_letra = ['','','',''];
                                                                 peso_letra = ['700','normal','normal','normal'];
                                                                 color_letra =  ['#47bb0e','#000','#000','#000'];
                                                                 break;
                                                             case 'B':
                                                                 clase_letra = ['','','',''];
                                                                 peso_letra = ['normal','700','normal','normal'];
                                                                 color_letra =  ['#000','#47bb0e','#000','#000'];
                                                                 break;
                                                             case 'C':
                                                                 clase_letra = ['','','',''];
                                                                 peso_letra = ['normal','normal','700','normal'];
                                                                 color_letra =  ['#000','#000','#47bb0e','#000'];
                                                                 break;
                                                             case 'D':
                                                                 clase_letra = ['','','',''];
                                                                 peso_letra = ['normal','normal','normal','700'];
                                                                 color_letra =  ['#000','#000','#000','#47bb0e'];
                                                                 break;
                                                         
                                                             default:
                                                                 clase_letra = ['','','',''];
                                                                 peso_letra = ['normal','normal','normal','normal'];
                                                                 color_letra =  ['#000','#000','#000','#000'];
                                                                 break;
                                                         }
                                                         if(respuesta.respuesta_correcta=='correcto'){
                                                             switch (respuesta.respuesta_usuario) {
                                                                 case 'A':
                                                                     icono = ['feather-check','d-none','d-none','d-none'];
                                                                     break;
                                                                 case 'B':
                                                                     icono = ['d-none','feather-check','d-none','d-none'];
                                                                     break;
                                                                 case 'C':
                                                                     icono = ['d-none','d-none','feather-check','d-none'];
                                                                     break;
                                                                 case 'D':
                                                                     icono = ['d-none','d-none','d-none','feather-check'];
                                                                     break;
                                                             
                                                                 default:
                                                                     icono = ['d-none','d-none','d-none','d-none'];
                                                                     break;
                                                             }
                                                         }else{
                                                             switch (respuesta.respuesta_usuario) {
                                                                 case 'A':
                                                                     clase_letra = ['off','','',''];
                                                                     icono = ['feather-x','d-none','d-none','d-none'];
                                                                     break;
                                                                 case 'B':
                                                                     clase_letra = ['','off','',''];
                                                                     icono = ['d-none','feather-x','d-none','d-none'];
                                                                     break;
                                                                 case 'C':
                                                                     clase_letra = ['','','off',''];
                                                                     icono = ['d-none','d-none','feather-x','d-none'];
                                                                     break;
                                                                 case 'D':
                                                                     clase_letra = ['','','','off'];
                                                                     icono = ['d-none','d-none','d-none','feather-x'];
                                                                     break;
                                                             
                                                                 default:
                                                                     icono = ['d-none','d-none','d-none','d-none'];
                                                                     break;
                                                             }
                                                         }
                                                         return(
                                                             <div className="col-md-6" key={index}>
                                                                 <div className="form-responsive">
                                                                 <p className="mb--10" style={{fontWeight:'700', color:'#000', fontSize:'22px'}}>Pregunta #{index + 1}</p>
                                                                     <p className="form-question">{pregunta.pregunta}</p>
                                                                     <ul className="plan-offer-list">
                                                                         {(pregunta.respuesta_a ? <li className={clase_letra[0] } style={{fontWeight: peso_letra[0], color: color_letra[0]}}>{pregunta.respuesta_a} <i className={icono[0]}></i></li> : null)}
                                                                         {(pregunta.respuesta_b ? <li className={clase_letra[1] } style={{fontWeight: peso_letra[1], color: color_letra[1]}}>{pregunta.respuesta_b} <i className={icono[1]}></i></li> : null)}
                                                                         {(pregunta.respuesta_c ? <li className={clase_letra[2] } style={{fontWeight: peso_letra[2], color: color_letra[2]}}>{pregunta.respuesta_c} <i className={icono[2]}></i></li> : null)}
                                                                         {(pregunta.respuesta_d ? <li className={clase_letra[3] } style={{fontWeight: peso_letra[3], color: color_letra[3]}}>{pregunta.respuesta_d} <i className={icono[3]}></i></li> : null)}
                                                                     </ul>
                                                                 </div>
                                                             </div>
                                                         );
                                                     }
                                                 })}
                                                     
                                                 </div>
                                                 <div className="d-flex justify-content-between p-4 mt--30" style={{ backgroundColor: '#f4f4f4'}}>
                                                        <p className="mb--0" style={{fontWeight:'700', color:'#000', fontSize:'20px', alignContent:'center'}}> Puntaje de la evaluación: {puntajeTotal} puntos </p>
                                                    <div className="">
                                                    <button
                                                        className={`rbt-btn btn-gradient icon-hover radius-round btn-sm ${mostrarResultados ? '' : 'd-none'}`}
                                                        onClick={() => {setMostrarVideo(true); setMostrarResultados(false); setMostrarEvaluacion(false); setVideoSeleccionado(0)}}
                                                    >
                                                        Volver a vídeo
                                                    </button>
                                                    </div>
                                                     
                                                 </div>
                                             </div>
                                        </div>
                                    ): null}
                                    {/*  End Viedo Wrapper  */}
    
                                    <div className="row row--30 gy-5 pt--60">
                                        {/* En esta seccion si hay recursos descarcables colocar la seccion1, de lo contrario que se habilite la 2 */}
                                        <div className="col-lg-4">
                                            <div className="course-sidebar sticky-top rbt-shadow-box">
                                                <div className="inner">
                                                <div className="content-item-content">
                                                    {listadoAnexos ?  (
                                                        // Sección 1
                                                        <div>
                                                        <div className="rbt-accordion-style rbt-accordion-02 accordion">
                                                            <div className="accordion" id="accordionAnexos">
                                                                <div className="accordion-item card">
                                                                    <h2 className="accordion-header card-header" id="headingOne1">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                                                                        Recursos
                                                                    </button>
                                                                    </h2>
                                                                    <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionAnexos">
                                                                        <div className="accordion-body card-body">
                                                                            {listadoAnexos && listadoAnexos.map((anexo, index) => (
                                                                            <a href="#" key={index}>Descargar: {anexo.documento}</a>
                                                                            ))}    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    ) : null }
                                                    {listadoSesiones ? (
                                                        // Sección 2
                                                        <>
                                                        <div className="section-title mt-4">
                                                            <h4 className="title mb--0">Próximas sesiones</h4>
                                                        </div>
                                                        {listadoSesiones && listadoSesiones.map((mini_sesion, index) => {
                                                            const fecha = new Date(mini_sesion.fecha_publicacion);
                                                            const fechaFormateada = format(fecha, 'dd/MMM');
                                                            return(
                                                                <div className="rbt-card event-grid-card variation-01 rbt-hover" key={index}>
                                                                    <div className="rbt-card-img">
                                                                        <a href="#">
                                                                        <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${mini_sesion.imagen_fondo}`} alt="Card image" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="rbt-card-body">
                                                                        <ul className="rbt-meta">
                                                                        <li>Experto <b>{mini_sesion.nombre_instructor}</b></li>
                                                                        <li><i className="feather-clock"></i>{fechaFormateada}</li>
                                                                        </ul>
                                                                        <h6 className=""><a href='#'>{mini_sesion.titulo}</a></h6>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        </>
                                                    ) : null}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
    
                                        <div className="col-lg-8">
                                            {/*  Start Course Details  */}
                                            <div className="course-details-content">
    
                                            <div className="course-content rbt-shadow-box coursecontent-wrapper" id="coursecontent">
                                                <div className="rbt-course-feature-inner">
                                                    <div className="section-title">
                                                        <h4 className="rbt-title-style-3">Contenido del curso</h4>
                                                    </div>
                                                    <ul className="rbt-course-main-content liststyle">
                                                        <li>
                                                            <a href="#" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                <div className="course-content-left" style={{ flexGrow: 1 }}>
                                                                    <i className="feather-play-circle"></i> <span className="text">Progreso del video</span>
                                                                    
                                                                </div>
                                                                <div className="course-content-right" style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                                                    {sesionVista ? (
                                                                        <>
                                                                        <span className="min-lable" style={{ marginLeft: '10px' }}>{datosSesion.duracion_aproximada}</span>
                                                                            <span className="rbt-badge variation-03 bg-color-success-opacity color-success ml--10 mb--0">
                                                                                <i className="feather-eye"></i> {estadoDelVideo}
                                                                            </span>
                                                                            <br />  
                                                                            {mostrarPuntajes ? (
                                                                                <span className="color-danger" style={{ fontSize: '16px' }}>
                                                                                    {puntajeMostrar} Puntos
                                                                                </span>
                                                                            ) : (
                                                                                <span className="color-danger" style={{ fontSize: '16px' }}>
                                                                                    No aplica
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                             {mostrarPuntajes ? (
                                                                                <span className="rbt-badge variation-03 bg-color-danger-opacity color-danger">
                                                                                    <i className="feather-eye-close"></i> Pendiente
                                                                                </span>
                                                                            ) : (
                                                                                <span className="rbt-badge variation-03 bg-color-danger-opacity color-danger">
                                                                                    <i className="feather-eye-close"></i> No aplica
                                                                                </span>
                                                                            )}
                                                                            
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="#" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                                <div className="course-content-left" style={{ flexGrow: 1 }}>
                                                                    <i className="feather-file-text"></i> <span className="text">Evaluación</span>
                                                                </div>
                                                                <div className="course-content-right" style={{ marginLeft: 'auto', textAlign: 'right' }}>
                                                                    {evaluacionCompleta ? (
                                                                        <>
                                                                            <span className="rbt-badge variation-03 bg-color-success-opacity color-success mb--0">
                                                                                <i className="feather-check"></i> Completado
                                                                            </span>
                                                                            <br />
                                                                            {mostrarPuntajes ? (
                                                                                <span className="color-danger" style={{ fontSize: '16px' }}>
                                                                                    {puntajeTotal} Puntos
                                                                                </span>
                                                                            ) : (
                                                                                <span className="color-danger" style={{ fontSize: '16px' }}>
                                                                                    No aplica
                                                                                </span>
                                                                            )}
                                                                            
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                        {mostrarPuntajes ? (
                                                                                <span className="rbt-badge variation-03 bg-color-danger-opacity color-danger">
                                                                                <i className="feather-lock"></i> {listadoPreguntas.length === 0 ?('No aplica'):('Pendiente')}
                                                                            </span>
                                                                            ) : (
                                                                                <span className="rbt-badge variation-03 bg-color-danger-opacity color-danger">
                                                                            <i className="feather-lock"></i> No aplica
                                                                        </span>
                                                                            )}
                                                                        </>
                                                                        
                                                                    )}
                                                                    <span className="course-lock"></span>
                                                                </div>
                                                            </a>
                                                        </li>

                                                    </ul>
                                                    <hr className="mt--10 mb--10"/>
                                                    {sesionVista && evaluacionCompleta && datosVisualizacion ? (
                                                        <div className="d-flex justify-content-between">
                                                        <div className="course-content-left" style={{color:'#000', fontWeight:'600'}}>
                                                            TOTAL
                                                        </div>
                                                        <div className="course-content-right" style={{float:'right'}}>
                                                            {mostrarPuntajes ? (
                                                                    <span className="color-danger"> {Number(datosVisualizacion.puntaje) + Number(puntajeTotal)} Puntos</span>
                                                                ) : (
                                                                    <span className="color-danger"> No aplica</span>
                                                                )}
                                                            
                                                        </div>
                                                        </div>
                                                    ):null }
                                                    
                                                    
                                                </div>
                                            </div>
                                                <hr className="mt--30 mb--0" />
    
                                                {/*  Comentarios  */}
                                                <div className="rbt-comment-area">
                                                    <div className="rbt-total-comment-post" style={{borderBottom: 'none'}}>
                                                        <div className="title">
                                                            <h4 className="mb--0">Preguntas al especialista</h4>
                                                        </div>
                                                        <div className="add-comment-button">
                                                            <a className="rbt-btn btn-gradient icon-hover radius-round btn-md" href="#pregunta">
                                                                <span className="btn-text text-white" href="#">Dejar mi pregunta</span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                {/*  Preguntas y respuestas, Aquí se colocaria de la prgunta mas reciente.  */}
                                                <div className="rbt-course-feature-box overview-wrapper rbt-shadow-box mt--30 has-show-more" id="preguntas">
                                                    <div className="rbt-course-feature-inner has-show-more-inner-content">
                                                        <div className="rbt-comment-area">
                                                        {listadoDudas ? (
                                                            <ul className="comment-list">
                                                                {/* Start Single Comment  */}
                                                                {listadoDudas && listadoDudas.map((duda, index) => {
                                                                    return(
                                                                        <li className="comment" key={index}>
                                                                            <div className="comment-body">
                                                                                <div className="single-comment">
                                                                                    <div className="comment-img">
                                                                                        <img src='/assets/images/micrositio/PLe_Profile-Pic_2024.png'  alt="Author Images" />
                                                                                    </div>
                                                                                    <div className="comment-inner">
                                                                                        <h6 className="commenter">
                                                                                            {duda.nombre} {duda.apellidos}
                                                                                        </h6>
                                                                                        <div className="comment-meta">
                                                                                            <div className="time-spent">{duda.created_at}</div>
                                                                                            <div className="reply-edit">
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="comment-text">
                                                                                            <p className="b2">{duda.duda}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {duda.respuesta !=='' ? (
                                                                                <ul className="children" style={{listStyle:'none'}}>
                                                                                    {/* Start Single Comment  */}
                                                                                    <li className="comment">
                                                                                        <div className="comment-body">
                                                                                            <div className="single-comment">
                                                                                                <div className="comment-img">
                                                                                                    <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${datosSesion.imagen_instructor}`}  alt={datosSesion.instructor} />
                                                                                                </div>
                                                                                                <div className="comment-inner">
                                                                                                    <h6 className="commenter">
                                                                                                        {datosSesion.nombre_instructor}
                                                                                                    </h6>
                                                                                                    <div className="comment-meta">
                                                                                                        <div className="time-spent">{duda.updated_at}</div>
                                                                                                        <div className="reply-edit">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="comment-text">
                                                                                                        {duda.respuesta}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    {/* End Single Comment  */}
                                                                                </ul>
                                                                            ):null}
                                                                        </li>
                                                                    );
                                                                
                                                                })}
                                                                {/* End Single Comment  */}
                                                            </ul>
                                                        ):null}
                                                        </div>
                                                    </div>
                                                    <div className="rbt-show-more-btn">Mostrar más</div>
                                                </div>
                                                
                                                {/*  End Course Feature Box  */}
    
                                                {/*  En esta sección se solicita que al momento de publicar una pregunta, se pueda activar  */}
                                                <div className="comment-respond" id="pregunta">
                                                    <h4 className="title">Escribe una pregunta para el Experto</h4>
                                                    <div className="row row--10">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label htmlFor="message">¿Cuál es tu duda?</label>
                                                                <textarea id="message" name="message" value={duda} onChange={escribirDuda}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 d-flex justify-content-end">
                                                            <button className="rbt-btn btn-gradient icon-hover radius-round btn-md" href="#" onClick={guardarDuda}>
                                                                <span className="btn-text">Publicar</span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                {/*  En esta seccion comenta Rob que se activara hasta 2025 */}
                                                {/* <div className="rbt-review-wrapper rbt-shadow-box review-wrapper mt--30" id="review">
                                                    <div className="course-content">
                                                        <div className="section-title">
                                                            <h4 className="rbt-title-style-3">Review</h4>
                                                        </div>
                                                        <div className="row g-5 align-items-center">
                                                            <div className="col-lg-3">
                                                                <div className="rating-box">
                                                                    <div className="rating-number">5.0</div>
                                                                    <div className="rating">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <span className="sub-title">Course Rating</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9">
                                                                <div className="review-wrapper">
                                                                    <div className="single-progress-bar">
                                                                        <div className="rating-text">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" style={{width: '63%'}} aria-valuenow="63" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <span className="value-text">63%</span>
                                                                    </div>
    
                                                                    <div className="single-progress-bar">
                                                                        <div className="rating-text">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" style={{width: '29%'}} aria-valuenow="29" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <span className="value-text">29%</span>
                                                                    </div>
    
                                                                    <div className="single-progress-bar">
                                                                        <div className="rating-text">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" style={{width: '6%'}} aria-valuenow="6" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <span className="value-text">6%</span>
                                                                    </div>
    
                                                                    <div className="single-progress-bar">
                                                                        <div className="rating-text">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" style={{width: '1%'}} aria-valuenow="1" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <span className="value-text">1%</span>
                                                                    </div>
    
                                                                    <div className="single-progress-bar">
                                                                        <div className="rating-text">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" style={{width: '1%'}} aria-valuenow="1" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <span className="value-text">1%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/*  End Edu Review List  */}
    
                                            </div>
                                            {/*  End Course Details  */}
    
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <SeccionDos />
                    
                </div>
            );
        }else{
            return (
                <div className='contenedor-principal'>
                    <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                        <div className="container">
                            {/* Start Card Area */}
                            <div className="row row-15">
                                <div className="col-lg-12 text-center p-4">
                                    <h5>Esta sesión aún no está disponible</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }else{
        return (
            <div className='contenedor-principal'>
                <div className="rbt-section-overlayping-top rbt-section-gapBottom masonary-wrapper-activation">
                    <div className="container">
                        {/* Start Card Area */}
                        <div className="row row-15">
                            <div className="col-lg-12 text-center p-4">
                                <h5>No hay datos para mostrar</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export function OtrasSesiones() {
    /**
     * ESTA SECCIÓN ES ESTÁTICA REQUIERE CAMBIAR EL CONTENIDO HTML
     */
    return (
        <div>
            <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">Otras Sesiones</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rbt-accordion-area accordion-style-1 bg-color-extra2 rbt-section-gap pt--40">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                            <div className="rbt-accordion-style accordion">
                                <div className="section-title text-start">
                                    <h2 className="title">Sesiones Extras</h2>
                                    <p className="description has-medium-font-size mt--20 mb--40">Como extra esta temporada, acreditar 10 cursos del programa de entrenamiento Panduit University, te otorgará hasta 6,000 puntos.</p>

                                </div>

                                <div className="rbt-accordion-style rbt-accordion-05 accordion">
                                    <div className="accordion" id="accordionExampleb4">
                                        <div className="accordion-item card">
                                            <h2 className="accordion-header card-header" id="headingFour1">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour1" aria-expanded="true" aria-controls="collapseFour1">
                                                Puntos
                                                </button>
                                            </h2>
                                            <div id="collapseFour1" className="accordion-collapse collapse show" aria-labelledby="headingFour1" data-bs-parent="#accordionExampleb4">
                                                <div className="accordion-body card-body">
                                                Cada curso te otorga 500 puntos, en total son 5,000 puntos. Más y 1,000 extra una vez completados y comprobados todos.
                                                Recuerda que por cada punto ganado, obtendrás también un crédito
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item card">
                                            <h2 className="accordion-header card-header" id="headingFour2">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour2" aria-expanded="false" aria-controls="collapseFour2">
                                                Cursos
                                                </button>
                                            </h2>
                                            <div id="collapseFour2" className="accordion-collapse collapse" aria-labelledby="headingFour2" data-bs-parent="#accordionExampleb4">
                                            <div className="accordion-body card-body" style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    Los cursos relevantes son <strong>Electrical Infrastructure Essentials</strong> y <strong>Welcome to Panduit IEI BU</strong>, en la Pestaña <strong>IEI Essentials</strong>, y <strong>UL 508A Panel Design Fundamentals</strong>, en la pestaña <strong>IEI Solutions</strong>, además de <strong>3 Cursos de OEM<strong> y </strong>4 de IC/MRO</strong>, en la pestaña <strong>IEI Solution Mobile Courses.</strong>
                                                </div>
                                                
                                            </div>




                                                
                                            </div>
                                        </div>

                                        <div className="accordion-item card">
                                            <h2 className="accordion-header card-header" id="headingFour3">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour3" aria-expanded="false" aria-controls="collapseFour3">
                                                Acreditación
                                                </button>
                                            </h2>
                                            <div id="collapseFour3" className="accordion-collapse collapse" aria-labelledby="headingFour3" data-bs-parent="#accordionExampleb4">
                                                <div className="accordion-body card-body">
                                                Para acreditar la participación en estas sesiones, el usuario deberá demostrar su participación mediante un correo electrónico que incluya los certificados de cada nivel durante la duración del programa P-Learning. De no hacerlo así, los puntos no se otorgarán bajo ninguna circunstancia.
                                                Deja tus certificados <a href="mailto:plearning@panduitlatam.com" style={{color:'#064a76'}}> ACÁ</a> 

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item card">
                                            <h2 className="accordion-header card-header" id="headingFour4">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour4" aria-expanded="false" aria-controls="collapseFour4">
                                                Términos y Condiciones 
                                                </button>
                                            </h2>
                                            <div id="collapseFour4" className="accordion-collapse collapse" aria-labelledby="headingFour4" data-bs-parent="#accordionExampleb4">
                                                <div className="accordion-body card-body">
                                                Si quieres mas detalles acerca de las dinámicas, revisa nuestros<a href="/TyC"> Términos y Condiciones.</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rbt-button-group" style={{ display: 'flex', justifyContent: 'space-between', paddingTop:'20px' }}>
                                                    <a className="rbt-btn btn-sm" href="https://panduit.csod.com/selfreg/register.aspx?c=%255e%255e%255eEbepPKUC58xDiW%252bO6%252foIgKIJtUQzgp8X8u4s606IkFI%253d">Registrarse </a>
                                                    <a className="rbt-btn btn-sm" style={{background:'#f0b323'}} href="https://pages.panduit.com/panduit-university.html#cursos">Tomar cursos </a>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail rbt-image-gallery-1 text-end" style={{marginTop:'-260px'}}>
                                <img className="image-1 rbt-radius" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 30}" src="/assets/images/micrositio/PLe_OtrasSesionesLogoA_2024.png" alt="Education Images" style={{transform:'translate3d(0px, 20.153px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)', WebkitTransform:'translate3d(0px, 20.153px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)'}} />
                                <img className="image-2 rbt-radius" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 80}" src="/assets/images/micrositio/PLe_OtrasSesionesGraficoA_2024.png" alt="Education Images" style={{transform:'translate3d(0px, 40.492px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)', WebkitTransform:'translate3d(0px, 40.492px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)'}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap pb--240">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="thumbnail rbt-image-gallery-1 mb--80">
                                <img className="image-1 rbt-radius" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 30}" src="/assets/images/micrositio/PLe_OtrasSesionesLogoB_2024.png" alt="Education Images" style={{transform:'translate3d(0px, 20.153px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)', WebkitTransform:'translate3d(0px, 20.153px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)'}} />
                                <img className="image-2 rbt-radius" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 80}" src="/assets/images/micrositio/PLe_OtrasSesionesGraficoB_2024.png" alt="Education Images" style={{transform:'translate3d(0px, 40.492px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)', WebkitTransform:'translate3d(0px, 40.492px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)'}} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title text-start">
                                <h2 className="title">Sesiones Especiales</h2>
                                <p className="description has-medium-font-size mt--20 mb--40">Mantente al pendiente, porque en un par de meses nuestros webinars de Soluciones eléctricas, te darán hasta 2,000 puntos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Trivia() {

    /**
     * Variables globales y datos
     */
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosTrivia, setDatosTrivia] = useState(null);
    const [listadoPreguntas, setListadoPreguntas] = useState(null);
    const [numeroPreguntas, setNumeroPreguntas] = useState(0)
    const [listadoRespuestas, setListadoRespuestas] = useState(null);
    const [listadoPremios, setListadoPremios] = useState(null);
    const regionLocal = JSON.parse(localStorage.getItem('Region'));
    const [regionState, setRegion] = useState(regionLocal);
    const [nivelDistribuidor, setNivelDistribuidor] = useState('oyente');
    /**
     * Variables de interactividad
     */
    const [selectedOptions, setSelectedOptions] = useState({});
    const [triviaCompleta, setTriviaCompleta] = useState(false);
    const [switchVista, setSwitchVista] = useState(true);
    const [mostrarTrivia, setMostrarTrivia] = useState(true);
    const [mostrarResultados, setMostrarResultados] = useState(false);
    const [respuestas, setRespuestas] = useState({ });
    const [puntajeTotal, setPuntajeTotal] = useState(0);
    const [puntajeHistorico, setPuntajeHistorico] = useState(0);
    const [misPremios, setMisPremios] = useState(0);
    const [soyGanador, setSoyGanador] = useState(false);
    const [fechaEstreno, setFechaEstreno] = useState(null);
    const [mostrarVistaTrivia, setMostrarVistaTrivia] = useState(false);
    /**
     * Funciones de interactividad
     */

    // Funciones de interacción del formulario
    const totalSteps = numeroPreguntas;

    const [step, setStep] = useState(0);
    const handleGroupChange = (group, selectedValue) => {
        setRespuestas({
            ...respuestas,
            [group]: selectedValue,
        });
    };
    const handlePrevious = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleNext = () => {
        if (step === 0) {
            setStep(step + 1);
        } else if (step >= totalSteps - 1) {
            // Aquí puedes llamar a tu función para manejar las respuestas
            guardarRespuestasTrivia();
        } else {
            setStep(step + 1);
        }
    };

    const guardarRespuestasTrivia = async () => {
        
        
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_respuestas_trivia', {
              id_usuario: id_local,
              id_trivia: datosTrivia.id,
              respuestas: respuestas,
              
            });
            console.log('se supone que se envió');
            console.log(response);
            setTriviaCompleta(true); 
            setSwitchVista(prevState => !prevState);
            
          } catch (error) {
            console.error('Error al enviar la solicitud:', error);
          } 
          
    };

    /**
     * Funciones de UseEffect
     */

    useEffect(() => {
        // llamada para los datos de la temporada
        axios.get('https://plsystem.quarkservers2.com/api/datos_temporada', {
            params: {
              id: cuentaPL
            }
          })
          .then(function (response) {
            //console.log('Temporada:'+response.data);
            setDatosTemporada(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    
    }, [cuentaPL]);

    useEffect(() => {
        
        if(datosTemporada){
            cargarContenidoServidor();
        }
      }, [datosTemporada, switchVista]); // Solo depende de id


    // Carga de datos del servidor
    const cargarContenidoServidor = async () => {
        
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            // Realiza la solicitud al servidor solo si id es válido
            axios.get('https://plsystem.quarkservers2.com/api/todos_datos_trivia', {
                params: {
                id_temporada: datosTemporada.id,
                id_usuario: id_local
                }
            })
            .then(function (response) {
                console.log(response.data);
                setDatosTrivia(response.data.trivia);
                setListadoPreguntas(response.data.preguntas);
                setNumeroPreguntas(response.data.preguntas.length);
                setListadoRespuestas(response.data.respuestas);
                setListadoPremios(response.data.premios);
                setPuntajeHistorico(response.data.respuestas_historico);
                setMisPremios(response.data.mis_premios.length);
                setSoyGanador(response.data.ganador);
                setNivelDistribuidor(response.data.nivel);
                const hoy = new Date();
                const fecha_estreno = new Date(response.data.trivia.fecha_publicacion);
                const fecha_vigencia = new Date(response.data.trivia.fecha_vigencia);
    
                // Array con los nombres de los meses en español
                const meses = [
                    "enero", "febrero", "marzo", "abril", "mayo", "junio",
                    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
                ];

                const dia = fecha_estreno.getDate();
                const mes = meses[fecha_estreno.getMonth()];
                setFechaEstreno(`${dia}-${mes}`);
                const respuestas = response.data.respuestas
                let conteo_puntos = 0;
                response.data.respuestas.forEach( respuesta =>{
                    if(respuesta.respuesta_correcta=='correcto'){
                        conteo_puntos+= parseInt(respuesta.puntaje, 10);
                    }
                });
                setPuntajeTotal(conteo_puntos);
                
                if(response.data.respuestas.length>0){
                    setMostrarTrivia(false);
                    setMostrarResultados(true);
                }else{
                    setMostrarTrivia(true);
                    setMostrarResultados(false);
                }

                if(hoy >= fecha_estreno){
                    setMostrarVistaTrivia(true);
                }

                if(hoy >= fecha_vigencia){
                    setMostrarTrivia(false);
                }
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
            });
    };
    
    
    if(!mostrarVistaTrivia){
        return (
            <div className='contenedor-principal'>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Trivia i Love Panduit</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0 pb--0">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <p className="description has-medium-font-size mt--20 mb--0">Bienvenido a nuestra Trivia mensual que premia tu velocidad.</p>
                                </div>
                            </div>
                        </div>
    
                        <div className="row row--15 mt_dec--30">
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico01_2024.png" alt="icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Accede</a></h6>
                                            <p className="description">en el día de lanzamiento de trivia</p>
                                        </div>
                                        <span className="number-text">1</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico02_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Responde </a></h6>
                                            <p className="description">correctamente y gana puntos</p>
                                        </div>
                                        <span className="number-text">2</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico03_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Participa</a></h6>
                                            <p className="description">en una de las 8 trivias de este 2024</p>
                                        </div>
                                        <span className="number-text">3</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico04_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Gana</a></h6>
                                            <p className="description">un premio al ser el primero de tu compañía en responder</p>
                                        </div>
                                        <span className="number-text">4</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico05_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Acumula</a></h6>
                                            <p className="description">puntos y créditos para participar por el premio final</p>
                                        </div>
                                        <span className="number-text">5</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}

                            <div className="container">
                            <div className="row row--15">
                                {/* Start Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>25 Octubre</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Próximo estreno</span>
                                    </div>
                                </div>
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{puntajeHistorico}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Puntos Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{misPremios}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Premios Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}

                            </div>
                            {/* End Card Area */}
                        </div>
    
                        </div>

                    </div>
                </div>

                <SeccionUno />

            </div>
        )
    }

    if (mostrarVistaTrivia&&nivelDistribuidor!='oyente'){
        return (
            <div className='contenedor-principal'>
    
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Trivia i Love Panduit</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <p className="description has-medium-font-size mt--20 mb--0">Bienvenido a nuestra Trivia mensual que premia tu velocidad.</p>
                                </div>
                            </div>
                        </div>
    
                        <div className="row row--15 mt_dec--30">
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico01_2024.png" alt="icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Accede</a></h6>
                                            <p className="description">en el día de lanzamiento de trivia</p>
                                        </div>
                                        <span className="number-text">1</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico02_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Responde </a></h6>
                                            <p className="description">correctamente y gana puntos</p>
                                        </div>
                                        <span className="number-text">2</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico03_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Participa</a></h6>
                                            <p className="description">en una de las 8 trivias de este 2024</p>
                                        </div>
                                        <span className="number-text">3</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico04_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Gana</a></h6>
                                            <p className="description">un premio al ser el primero de tu compañía en responder</p>
                                        </div>
                                        <span className="number-text">4</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                            {/*  Start Service Grid  */}
                            <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_iLPico05_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Acumula</a></h6>
                                            <p className="description">puntos y créditos para participar por el premio final</p>
                                        </div>
                                        <span className="number-text">5</span>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
    
                        </div>
    
                        <div className="row pt--60 g-5">
                            <div className="col-lg-4">
                                <div className="thumbnail">
                                    <img className="radius-10 w-100" src="/assets/images/micrositio/PLe_iLPbann_2024.png" alt="Corporate Template" />
                                </div>
                            </div>
    
                            {/* Formulario Multipasos */}
                            {mostrarTrivia ? (
                            <div className="col-lg-8">
                                <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                                    <div className="section-title text-start">
                                        <span className="subtitle text-white">TRIVIA {datosTrivia.titulo}</span>
                                    </div>
                                    <h3 className="title">Trivia i Love Panduit</h3>
                                    <hr className="mb--30" />
    
                                    <div>
                                        <h3 className="title" style={{fontWeight:'500', fontSize:'24px'}}>Pregunta {step + 1}/{totalSteps}</h3>
    
                                        <div className='progress-bar'>
                                            <div className='progress' style={{width: step === 0 ? '33%' : '80%'}}></div>
                                        </div>
    
                                        <div className='container'>
                                        {listadoPreguntas && listadoPreguntas.map((pregunta, index) => {
                                            
                                            return(
                                                <div key={index} className={index === step ? "" : "d-none"}>
                                                    <RadioButtonGroupTrivia
                                                        groupName={`pregunta-${pregunta.id}`}
                                                        id_pregunta={pregunta.id}
                                                        pregunta={pregunta.pregunta}
                                                        options={[
                                                        ...(pregunta.respuesta_a ? [{ label: pregunta.respuesta_a, value: 'A' }] : []),
                                                        ...(pregunta.respuesta_b ? [{ label: pregunta.respuesta_b, value: 'B' }] : []),
                                                        ...(pregunta.respuesta_c ? [{ label: pregunta.respuesta_c, value: 'C' }] : []),
                                                        ...(pregunta.respuesta_d ? [{ label: pregunta.respuesta_d, value: 'D' }] : []),
                                                        ]}
                                                        onChange={handleGroupChange}
                                                    />
                                                </div>
                                            );
                                        })}
                                        </div>
    
                                        <div className='btn-container'>
                                            <button className="rbt-btn btn-gradient icon-hover" disabled={step === 0} onClick={handlePrevious}>
                                                <span className="btn-text">Anterior</span>
                                                <span className="btn-icon"><i className="feather-arrow-left"></i></span>
                                            </button>
                                            
                                            {step === totalSteps - 1  ? (
                                                <button className="rbt-btn btn-gradient icon-hover" onClick={guardarRespuestasTrivia}>
                                                    <span className="btn-text">Enviar Respuestas</span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                </button>
                                            ) : (
                                                <button className="rbt-btn btn-gradient icon-hover" onClick={handleNext}>
                                                    <span className="btn-text">Siguiente</span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ):null}
    
                            {/* Este la vista donde se pondras las respuestas correctas e incorrectas, con estos estilos :D */}
                            {mostrarResultados ? (
                            <div className="col-lg-8">
                                <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                                    <div className="section-title text-start">
                                        <span className="subtitle text-white">TRIVIA {datosTrivia.titulo}</span>
                                    </div>
                                    <h3 className="title">Trivia i Love Panduit</h3>
                                    <hr className="mb--30" />
    
                                    <div>
                                    {soyGanador ? (
                                            <h3 className="title" style={{ fontWeight: '700', fontSize: '20px', color: '#213746' }}>¡Felicidades!... estos son tus resultados:</h3>
                                        ) : (
                                            <h3 className="title" style={{ fontWeight: '700', fontSize: '20px', color: '#213746' }}>¡Gracias por participar!... estos son tus resultados:</h3>
                                        )}

                                        <div className='progress-bar'>
                                            <div className='progress' style={{ width: '100%' }}></div>
                                        </div>
    
                                        <div className="row">
                                        {listadoPreguntas && listadoPreguntas.map((pregunta, index) => {
                                            const respuesta = listadoRespuestas.find(respuesta => respuesta.id_pregunta === pregunta.id);
                                            if(respuesta){
                                                
                                                console.log('Respuesta: '+respuesta.respuesta_correcta);
                                                let clase_letra = ['','','',''];
                                                let peso_letra = ['normal','normal','normal','normal'];
                                                let color_letra =  ['#000','#000','#000','#000'];
                                                let icono = ['d-none','d-none','d-none','d-none'];
                                                switch (pregunta.respuesta_correcta) {
                                                    case 'A':
                                                        clase_letra = ['','','',''];
                                                        peso_letra = ['700','normal','normal','normal'];
                                                        color_letra =  ['#47bb0e','#000','#000','#000'];
                                                        break;
                                                    case 'B':
                                                        clase_letra = ['','','',''];
                                                        peso_letra = ['normal','700','normal','normal'];
                                                        color_letra =  ['#000','#47bb0e','#000','#000'];
                                                        break;
                                                    case 'C':
                                                        clase_letra = ['','','',''];
                                                        peso_letra = ['normal','normal','700','normal'];
                                                        color_letra =  ['#000','#000','#47bb0e','#000'];
                                                        break;
                                                    case 'D':
                                                        clase_letra = ['','','',''];
                                                        peso_letra = ['normal','normal','normal','700'];
                                                        color_letra =  ['#000','#000','#000','#47bb0e'];
                                                        break;
                                                
                                                    default:
                                                        clase_letra = ['','','',''];
                                                        peso_letra = ['normal','normal','normal','normal'];
                                                        color_letra =  ['#000','#000','#000','#000'];
                                                        break;
                                                }
                                                if(respuesta.respuesta_correcta=='correcto'){
                                                    switch (respuesta.respuesta_usuario) {
                                                        case 'A':
                                                            icono = ['feather-check','d-none','d-none','d-none'];
                                                            break;
                                                        case 'B':
                                                            icono = ['d-none','feather-check','d-none','d-none'];
                                                            break;
                                                        case 'C':
                                                            icono = ['d-none','d-none','feather-check','d-none'];
                                                            break;
                                                        case 'D':
                                                            icono = ['d-none','d-none','d-none','feather-check'];
                                                            break;
                                                    
                                                        default:
                                                            icono = ['d-none','d-none','d-none','d-none'];
                                                            break;
                                                    }
                                                }else{
                                                    switch (respuesta.respuesta_usuario) {
                                                        case 'A':
                                                            clase_letra = ['off','','',''];
                                                            icono = ['feather-x','d-none','d-none','d-none'];
                                                            break;
                                                        case 'B':
                                                            clase_letra = ['','off','',''];
                                                            icono = ['d-none','feather-x','d-none','d-none'];
                                                            break;
                                                        case 'C':
                                                            clase_letra = ['','','off',''];
                                                            icono = ['d-none','d-none','feather-x','d-none'];
                                                            break;
                                                        case 'D':
                                                            clase_letra = ['','','','off'];
                                                            icono = ['d-none','d-none','d-none','feather-x'];
                                                            break;
                                                    
                                                        default:
                                                            icono = ['d-none','d-none','d-none','d-none'];
                                                            break;
                                                    }
                                                }
                                                return(
                                                    <div className="col-md-6" key={index}>
                                                        <div className="form-responsive">
                                                            <p className="form-question">{pregunta.pregunta}</p>
                                                            <ul className="plan-offer-list">
                                                                {(pregunta.respuesta_a ? <li className={clase_letra[0] } style={{fontWeight: peso_letra[0], color: color_letra[0]}}>{pregunta.respuesta_a} <i className={icono[0]}></i></li> : null)}
                                                                {(pregunta.respuesta_b ? <li className={clase_letra[1] } style={{fontWeight: peso_letra[1], color: color_letra[1]}}>{pregunta.respuesta_b} <i className={icono[1]}></i></li> : null)}
                                                                {(pregunta.respuesta_c ? <li className={clase_letra[2] } style={{fontWeight: peso_letra[2], color: color_letra[2]}}>{pregunta.respuesta_c} <i className={icono[2]}></i></li> : null)}
                                                                {(pregunta.respuesta_d ? <li className={clase_letra[3] } style={{fontWeight: peso_letra[3], color: color_letra[3]}}>{pregunta.respuesta_d} <i className={icono[3]}></i></li> : null)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                            
                                        </div>
                                        <div style={{ textAlign: 'center', fontWeight: '600', color: '#213746', paddingTop: '3rem', fontSize: '20px' }}>
                                            <p> Puntaje Total de la actividad: <br />
                                                <strong>{puntajeTotal} puntos</strong> </p>
                                        </div>
                                        {/* Texto del ganador */}
                                        <div className='' style={{paddingTop:'6rem'}}>
                                            <div className='row'>
                                                <div className='col-10'>
                                                {soyGanador ? (
                                                    <p style={{fontWeight:'700', color:'red', fontSize:'20px'}}>Eres el mejor concursante de tu compañía, <br/> {regionState=='RoLA' ? ('pronto recibirás un correo con tu premio.'):('Comparte tu dirección para enviarte un premio') }</p>
                                                ):null}
                                                </div>
                                                <div className='col-2'>
                                                <a className="rbt-btn btn-gradient icon-hover" href={regionState=='RoLA' ? ('/'):('/ilovepanduit/direccion') }>
                                                    <span className="btn-text">{regionState=='RoLA' ? ('Salir'):('Canjear Premio') }</span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                </a>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            ):null}
    
                        </div>
                        
                        {/* <div className="row g-5" style={{paddingTop:'30px'}}>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                            <h2 className="">{fechaEstreno}</h2>
                                            <span className="subtitle">Próximo estreno</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                            <h2 className="">{puntajeHistorico}</h2>
                                            <span className="subtitle">Puntos Ganados</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                        <h2 className="">{misPremios}</h2>
                                            <span className="subtitle">Premios Ganados</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="container">
                            <div className="row row--15">
                                {/* Start Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{fechaEstreno}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Próximo estreno</span>
                                    </div>
                                </div>
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{puntajeHistorico}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Puntos Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{misPremios}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Premios Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}

                            </div>
                            {/* End Card Area */}
                        </div>

                    </div>
                </div>
    
                <SeccionUno />
            </div>
        );
    }
}

export function TriviaDireccion() {

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosTrivia, setDatosTrivia] = useState(null);
    const [listadoPreguntas, setListadoPreguntas] = useState(null);
    const [numeroPreguntas, setNumeroPreguntas] = useState(0)
    const [listadoRespuestas, setListadoRespuestas] = useState(null);
    const [listadoPremios, setListadoPremios] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [triviaCompleta, setTriviaCompleta] = useState(false);
    const [switchVista, setSwitchVista] = useState(true);
    const [mostrarTrivia, setMostrarTrivia] = useState(true);
    const [mostrarResultados, setMostrarResultados] = useState(false);
    const [respuestas, setRespuestas] = useState({ });
    const [puntajeTotal, setPuntajeTotal] = useState(0);
    const [puntajeHistorico, setPuntajeHistorico] = useState(0);
    const [misPremios, setMisPremios] = useState(0);
    const [soyGanador, setSoyGanador] = useState(false);
    const [premioGanador, setPremioGanador] = useState(null);
    const regionLocal = JSON.parse(localStorage.getItem('Region'));
    const [regionState, setRegion] = useState(regionLocal);
    const [fechaEstreno, setFechaEstreno] = useState(null);
    const [editarDireccion, setEditarDireccion] = useState(true);
    const [actualizandoDireccion, setActualizandoDireccion] = useState(false);
    const [direccionConfirmada, setDireccionConfirmada] = useState('no');
    const [confirmandoDireccion, setConfirmandoDireccion] = useState(false);

    const [formData, setFormData] = useState({
        id_premio: '',
        nombre: '',
        numero: '',
        calle: '',
        colonia: '',
        delegacion: '',
        ciudad: '',
        codigoPostal: '',
        horario: '',
        referencia: '',
        notas: '',
        telefono: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setActualizandoDireccion(true);
        
        axios.post('https://plsystem.quarkservers2.com/api/direccion_trivia', formData)
            .then(response => {
                //console.log(response.data);
                setEditarDireccion(false);
                setActualizandoDireccion(false);
                setSwitchVista(!switchVista);
                Swal.fire({
                    title: '¡Dirección guardada, no olvides confirmar tu envio!',
                    icon: 'success',
                        confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                });
            })
            .catch(error => {
                console.error('Hubo un error!', error);
            });
            
    };

    const confirmarDireccion = () => {
        console.log(formData);
        setConfirmandoDireccion(true);
        
        axios.post('https://plsystem.quarkservers2.com/api/confirmar_direccion_trivia', formData)
            .then(response => {
                console.log(response.data);
                setEditarDireccion(false);
                setConfirmandoDireccion(false);
                setDireccionConfirmada('si');
                setSwitchVista(!switchVista);
                Swal.fire({
                    title: '¡Gracias tu dirección ha sido recibida!',
                    icon: 'success',
                        confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                });
            })
            .catch(error => {
                console.error('Hubo un error!', error);
            });
            
    };
    
    
    


    // Carga de datos del servidor
    const cargarContenidoServidor = async () => {
        
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            // Realiza la solicitud al servidor solo si id es válido
            axios.get('https://plsystem.quarkservers2.com/api/todos_datos_trivia', {
                params: {
                id_temporada: datosTemporada.id,
                id_usuario: id_local
                }
            })
            .then(function (response) {
                console.log(response.data);
                setDatosTrivia(response.data.trivia);
                setListadoPreguntas(response.data.preguntas);
                setNumeroPreguntas(response.data.preguntas.length);
                setListadoRespuestas(response.data.respuestas);
                setListadoPremios(response.data.premios);
                setPuntajeHistorico(response.data.respuestas_historico);
                setMisPremios(response.data.mis_premios.length);
                setSoyGanador(response.data.ganador);
                setPremioGanador(response.data.premio_ganador);
                setDireccionConfirmada(response.data.premio_ganador.direccion_confirmada);
                setFormData({
                    id_premio: response.data.premio_ganador.id,
                    nombre: response.data.premio_ganador.direccion_nombre,
                    numero: response.data.premio_ganador.direccion_numero,
                    numeroint: response.data.premio_ganador.direccion_numeroint,
                    calle: response.data.premio_ganador.direccion_calle,
                    colonia: response.data.premio_ganador.direccion_colonia,
                    delegacion: response.data.premio_ganador.direccion_delegacion,
                    ciudad: response.data.premio_ganador.direccion_ciudad,
                    codigoPostal: response.data.premio_ganador.direccion_codigo_postal,
                    horario: response.data.premio_ganador.direccion_horario,
                    referencia: response.data.premio_ganador.direccion_referencia,
                    notas: response.data.premio_ganador.direccion_notas,
                    telefono: response.data.premio_ganador.direccion_telefono
                });
                if(response.data.premio_ganador.direccion_calle){
                    setEditarDireccion(false);
                }
                const fecha_estreno = new Date(response.data.trivia.fecha_publicacion);
    
                // Array con los nombres de los meses en español
                const meses = [
                    "enero", "febrero", "marzo", "abril", "mayo", "junio",
                    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
                ];

                const dia = fecha_estreno.getDate();
                const mes = meses[fecha_estreno.getMonth()];
                setFechaEstreno(`${dia}-${mes}`);
                const respuestas = response.data.respuestas
                let conteo_puntos = 0;
                response.data.respuestas.forEach( respuesta =>{
                    if(respuesta.respuesta_correcta=='correcto'){
                        conteo_puntos+= parseInt(respuesta.puntaje, 10);
                    }
                });
                setPuntajeTotal(conteo_puntos);
                
                if(response.data.respuestas.length>0){
                    setMostrarTrivia(false);
                    setMostrarResultados(true);
                }else{
                    setMostrarTrivia(true);
                    setMostrarResultados(false);
                }
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
            });
    };
    

    useEffect(() => {
        // llamada para los datos de la temporada
        axios.get('https://plsystem.quarkservers2.com/api/datos_temporada', {
            params: {
              id: cuentaPL
            }
          })
          .then(function (response) {
            console.log('Temporada:'+response.data);
            setDatosTemporada(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    
    }, [cuentaPL]);

    useEffect(() => {
        
        if(datosTemporada){
            cargarContenidoServidor();
        }
      }, [datosTemporada, switchVista]); // Solo depende de id


    /**
     * Funciones de interacción del formulario
     */
    const totalSteps = numeroPreguntas;

    const [step, setStep] = useState(0);


    

    if(soyGanador&&regionState!='RoLA'){
        return (
            <div className='contenedor-principal'>
    
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Trivia i Love Panduit</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <p className="description has-medium-font-size mt--20 mb--0">{direccionConfirmada=='si' ? (''):('Eres el ganador por favor comparte tu dirección con nosotros')}</p>
                                </div>
                            </div>
                        </div>
    
    
                        <div className="row pt--60 g-5">
                        {mostrarResultados ? (
                            <div className="col-lg-12">
                                <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                                    <div className="section-title text-start">
                                        <span className="subtitle text-white">TRIVIA {datosTrivia.titulo}</span>
                                    </div>
                                    <h3 className="title">{direccionConfirmada=='si' ? ('Tu paquete será enviado a la siguiente dirección'):('Comparte tu dirección')}</h3>
                                    <hr className="mb--30" />
    
                                    <div>
                                    {editarDireccion ? (
                                    <form onSubmit={handleSubmit} className="rbt-profile-row rbt-default-form row row--15">
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="nombre">Nombre completo <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="nombre" placeholder="Ingresa tu nombre y apellidos" value={formData.nombre} onChange={handleChange}/>
                                            </div>
                                            
                                            <div className="form-group col-md-4">
                                                <label htmlFor="calle">Calle <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input className="form-control" id="calle" rows="2" placeholder="Ingresa la calle" value={formData.calle} onChange={handleChange}></input>
                                            </div>

                                            <div className="form-group col-md-2">
                                                <label htmlFor="numero">Número Ext <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="numero" placeholder="Ingresa el número" value={formData.numero} onChange={handleChange}/>
                                            </div>

                                            <div className="form-group col-md-2">
                                                <label htmlFor="numeroint">Número Int</label>
                                                <input type="text" className="form-control" id="numeroint" placeholder="Ingresa el número" value={formData.numeroint} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            
                                            <div className="form-group col-md-6">
                                                <label htmlFor="colonia">Colonia <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="colonia" placeholder="Ingresa la colonia" value={formData.colonia} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="delegacion">Alcaldía / Municipio <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="delegacion" placeholder="Ingresa la alcaldía o municipio" value={formData.delegacion} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="ciudad">Ciudad <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="ciudad" placeholder="Ingresa la ciudad" value={formData.ciudad} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="codigoPostal">Código Postal <strong style={{color:'#064a76'}}>*</strong></label>
                                                <input type="text" className="form-control" id="codigoPostal" placeholder="Ingresa el código postal" value={formData.codigoPostal} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="horario">Horario preferente</label>
                                                <input type="text" className="form-control" id="horario" placeholder="Ingresa un horario preferente" value={formData.horario} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label htmlFor="telefono">Teléfono</label>
                                            <input type="text" className="form-control" id="telefono" placeholder="Ingresa un numero telefónico" value={formData.telefono} onChange={handleChange}/>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="referencia">Referencia</label>
                                                <textarea className="form-control" id="referencia" rows="2" placeholder="Compártenos referencias para ubicar mejor tu dirección" value={formData.referencia} onChange={handleChange}></textarea>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label htmlFor="notas">Notas</label>
                                                <textarea className="form-control" id="notas" rows="3" placeholder="Escribe observaciones a considerar para tu entrega" value={formData.notas} onChange={handleChange}></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="text-right" style={{paddingRight:'20px'}}>
                                            <span style={{fontSize:'12px'}}>Los campos marcados con <strong style={{color:'#064a76'}}>*</strong> son obligatorios</span>
                                            <button type="submit" className="rbt-btn btn-gradient icon-hover" style={{ width: '150px', float:'right' }} disabled={!actualizandoDireccion ? (false):(true)}>
                                            {!actualizandoDireccion ? ('Enviar'):('Enviando...')}
                                            </button>
                                        </div>
                                    </form>
                                    ):(
                                        <>

                                        <div>
                                            <label htmlFor="address" style={{cursor:'pointer'}}>
                                                <strong>{premioGanador.direccion_nombre} </strong>
                                                {premioGanador.direccion_calle} # {premioGanador.direccion_numero} Int: {premioGanador.direccion_numeroint}, {premioGanador.direccion_colonia}, {formData.delegacion}, {premioGanador.direccion_codigo_postal}, {premioGanador.direccion_ciudad}, Tel: {premioGanador.direccion_telefono} {direccionConfirmada=='no' ? ( <>| <a style={{color:'#0A78C2', fontWeight:'600'}} onClick={() => { setEditarDireccion(true)}}>Editar</a></> ):null}
                                                {/* Horario: {premioGanador.direccion_horario} <br></br>
                                                Referencias: {premioGanador.direccion_referencia} <br></br>                 
                                                Nota:  {premioGanador.direccion_notas} <br></br> */}
                                            </label>
                                        </div>
                                        {direccionConfirmada=='no' ? ( 
                                        <div className="mt--20">
                                            <button type="button" disabled={!confirmandoDireccion ? (false):(true)} className="rbt-btn btn-gradient icon-hover" onClick={() => { confirmarDireccion()}} style={{float:'right'}}>{!confirmandoDireccion ? ('Confirmar el envío'):('Confirmando')}</button>
                                        </div>
                                        ):null}

                                        <br></br>

                                        
                                        </>
                                    )}
                                    </div>
                                </div>
                            </div> 
                            ):null}
    
                        </div>
                        
                        {/* <div className="row g-5" style={{paddingTop:'30px'}}>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                            <h2 className="">{fechaEstreno}</h2>
                                            <span className="subtitle">Próximo estreno</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                            <h2 className="">{puntajeHistorico}</h2>
                                            <span className="subtitle">Puntos Ganados</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rbt-counterup style-3">
                                    <div className="inner">
                                        <div className="content">
                                        <h2 className="">{misPremios}</h2>
                                            <span className="subtitle">Premios Ganados</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="container">
                            <div className="row row--15">
                                {/* Start Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{fechaEstreno}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Próximo estreno</span>
                                    </div>
                                </div>
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{puntajeHistorico}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Puntos Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>{misPremios}</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Premios Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}

                            </div>
                            {/* End Card Area */}
                        </div>

                    </div>
                </div>
    
                <SeccionUno />
            </div>
        );
    }else{
        return('Sección exclusiva para región México')
    }
}

export function Jackpot() {

    
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [datosJackpot, setDatosJackpot] = useState(null);
    const [listadoPreguntas, setListadoPreguntas] = useState(null);
    const [numeroPreguntas, setNumeroPreguntas] = useState(0)
    const [listadoRespuestas, setListadoRespuestas] = useState(null);
    const [listadoIntentos, setListadoIntentos] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [triviaCompleta, setTriviaCompleta] = useState(false);
    const [limiteIntentos, setLimiteIntentos] = useState(0);
    const [switchVista, setSwitchVista] = useState(true);
    
    /**
     * Funciones de animación del Jackpot
     */
    const [mostrarVistaJackpot, setMostrarVistaJackpot] = useState(false);
    const [animationActive, setAnimationActive] = useState(false);
    const [mensajeVisible, setMensajeVisible] = useState(true);
    const [results, setResults] = useState('');
    const [premiosGanados, setPremiosGanados] = useState(0);
    const [puntajeTotal, setPuntajeTotal] = useState(0);
    const [clickDisabled, setClickDisabled] = useState(false);

    /**
     * Estas son las variables que muestran o no muestran elementos
     */
    const [mostrarInstrucciones, setMostrarInstrucciones] = useState(true);
    const [mostrarMaquina, setMostrarMaquina] = useState(false);
    const [mostrarTrivia, setMostrarTrivia] = useState(false);
    const [mostrarResultados, setMostrarResultados] = useState(false);
    const [triviaCorrecta, setTriviaCorrecta] = useState(null);
    const [respuestas, setRespuestas] = useState({ });

    /**
     * Obtengo los datos del servidor
     */

    const totalSteps = numeroPreguntas;
    const [step, setStep] = useState(0);
    const handleGroupChange = (group, selectedValue) => {
        setRespuestas({
            ...respuestas,
            [group]: selectedValue,
        });
    };

    const handlePrevious = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleNext = () => {
        if (step === 0) {
            setStep(step + 1);
        } else if (step >= totalSteps - 1) {
            // Aquí puedes llamar a tu función para manejar las respuestas
            guardarRespuestasjackpot();
        } else {
            setStep(step + 1);
        }
    };



    const guardarRespuestasjackpot = async () => {
        
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_respuestas_jackpot', {
              id_usuario: id_local,
              id_jackpot: datosJackpot.id,
              respuestas: respuestas,
              
            });
            //console.log('se supone que se envió');
            //console.log(response);
            setTriviaCompleta(true); 
            setMostrarTrivia(false);
            setSwitchVista(prevState => !prevState);
            
          } catch (error) {
            console.error('Error al enviar la solicitud:', error);
          } 
    };

    const cargarContenidoServidor = async () => {
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        const region = JSON.parse(localStorage.getItem('Region'));
            // Realiza la solicitud al servidor solo si id es válido
            axios.get('https://plsystem.quarkservers2.com/api/todos_datos_jackpot', {
                params: {
                id_temporada: datosTemporada.id,
                id_usuario: id_local
                }
            })
            .then(function (response) {
                
                setDatosJackpot(response.data.jackpot);
                setListadoPreguntas(response.data.preguntas);
                setListadoRespuestas(response.data.respuestas);
                setListadoIntentos(response.data.intentos);
                setDatosDistribuidor(response.data.distribuidor);
                setDatosSuscripcion(response.data.suscripcion);
                //console.log(response.data.distribuidor);
                //console.log(response.data.suscripcion);

                //console.log(response.data.respuestas.length)
                const limiteIntentos = response.data.jackpot.intentos;
                const respuestas = response.data.respuestas;
                const intentos = response.data.intentos;
                const regionJackpot = response.data.jackpot.region;
                const regionDistribuidor = response.data.distribuidor.region
                // console.log(respuestas);
                const hoy = new Date();
                const fecha_estreno = new Date(response.data.jackpot.fecha_publicacion);
                const fecha_vigencia = new Date(response.data.jackpot.fecha_vigencia);

                
                setMostrarTrivia(true);
                
                if(respuestas.length>0){
                    setTriviaCompleta(true)
                    let trivia_correcta = true;    
                    {respuestas.map((respuesta, index) => {
                        if(respuesta.respuesta_correcta=='incorrecto'){
                            trivia_correcta = false;  
                        }
                    })}       
                    if(!trivia_correcta){
                        console.log('trivia incorrecta');
                        setMostrarMaquina(false);
                        setMostrarTrivia(false);
                        setMostrarResultados(true);
                        
                        setTriviaCorrecta(false);
                    }else{
                        setMostrarMaquina(true);
                        setMostrarTrivia(false);
                        setMostrarResultados(true);
                        setTriviaCorrecta(true);
                    }       
                }
                
                if(intentos.length>=limiteIntentos){
                    //console.log('se alcanza el límite')
                    setMostrarMaquina(false);
                    setMostrarResultados(true);
                }
                if(hoy >= fecha_estreno){
                    setMostrarVistaJackpot(true);
                }

                if(hoy >= fecha_vigencia){
                    setMostrarVistaJackpot(false);
                }
                if(regionJackpot!='Todas'){
                    if(regionJackpot!=regionDistribuidor){
                        setMostrarVistaJackpot(false);
                    }
                }
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
            });
    };

    const guardarIntento = async (tiro, slot_1, slot_2, slot_3, slot_premio, puntaje) => {
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));

        console.log('usuario: '+id_local);
        console.log('Jackpot: '+datosJackpot.id);
        console.log('Tiro: '+tiro);
        console.log('Slot 1: '+slot_1);
        console.log('Slot 2: '+slot_2);
        console.log('Slot 3: '+slot_3);
        console.log('Slot premio: '+slot_premio);
        console.log('Puntaje: '+puntaje);
        
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/registrar_intento_jackpot', {
              id_usuario: id_local,
              id_jackpot: datosJackpot.id,
              tiro: tiro,
              slot_1: slot_1,
              slot_2: slot_2,
              slot_3: slot_3,
              slot_premio: slot_premio,
              puntaje: puntaje,
            });
            setTimeout(() => {
                setSwitchVista(prevState => !prevState);
              }, 4000);
            
            
          } catch (error) {
            console.error('Error al enviar la solicitud:', error);
          } 
    };
  

    useEffect(() => {
        // llamada para los datos de la temporada
        axios.get('https://plsystem.quarkservers2.com/api/datos_temporada', {
            params: {
              id: cuentaPL
            }
          })
          .then(function (response) {
            //console.log('Temporada:'+response.data);
            setDatosTemporada(response.data);

            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    
    }, [cuentaPL]);

    useEffect(() => {
        
        if(datosTemporada){
            cargarContenidoServidor();
        }
      }, [datosTemporada, switchVista]); // Solo depende de id
  
      const jalarPalanca = () => {
        setAnimationActive(true);
        setMensajeVisible(false);

        /**
         * Lógica de la animación
         */

        /**
         * Hago el tiro
         */
        let tiro = Math.floor(Math.random() * 100) + 1; /** * está es la posibilidad completa */
        //let tiro = Math.floor(Math.random() * 50) + 51; /** * está está truqueado siempre saldrá + de 50 por lo cual nadie perderá*/
        
        const arregloDePremios = [0, 100, 200, 200, 200, 200, 200, 300];

        let slot = '';
        let premio = '';

            /**
         * Dependiendo del tiro asigno el slot y el premio
         */

        if (tiro >= 1 && tiro <= 50) {
        slot = '0';
        premio = arregloDePremios[0];
        } else if (tiro >= 51 && tiro <= 65) {
        slot = '1';
        premio = arregloDePremios[1];
        } else if (tiro >= 66 && tiro <= 70) {
        slot = '2';
        premio = arregloDePremios[2];
        } else if (tiro >= 71 && tiro <= 75) {
        slot = '3';
        premio = arregloDePremios[3];
        } else if (tiro >= 75 && tiro <= 80) {
        slot = '4';
        premio = arregloDePremios[4];
        } else if (tiro >= 81 && tiro <= 85) {
        slot = '5';
        premio = arregloDePremios[5];
        } else if (tiro >= 86 && tiro <= 90) {
        slot = '6';
        premio = arregloDePremios[6];
        } else if (tiro >= 91 && tiro <= 100) {
        slot = '7';
        premio = arregloDePremios[7];
        }

        

        /**
         * reinicio las casillas
         */

        let casilla_1 = 0;
        let casilla_2 = 0;
        let casilla_3 = 0;
        

        /**
     * Dependiendo del slot defino las casillas
     */

        switch (slot) {
            case '0':
            casilla_1 = Math.floor(Math.random() * 6);
            casilla_2 = Math.floor(Math.random() * 6);
            if (casilla_2 === casilla_1) {
                casilla_2 = Math.floor(Math.random() * 6);
                if (casilla_2 === casilla_1) {
                casilla_2 = Math.floor(Math.random() * 6);
                }
            }
            casilla_3 = Math.floor(Math.random() * 6);
            break;
            case '1':
            casilla_1 = 0;
            casilla_2 = 0;
            casilla_3 = 0;
            break;
            case '2':
            casilla_1 = 1;
            casilla_2 = 1;
            casilla_3 = 1;
            break;
            case '3':
            casilla_1 = 2;
            casilla_2 = 2;
            casilla_3 = 2;
            break;
            case '4':
            casilla_1 = 3;
            casilla_2 = 3;
            casilla_3 = 3;
            break;
            case '5':
            casilla_1 = 4;
            casilla_2 = 4;
            casilla_3 = 4;
            break;
            case '6':
            casilla_1 = 5;
            casilla_2 = 5;
            casilla_3 = 5;
            break;
            case '7':
            casilla_1 = 6;
            casilla_2 = 6;
            casilla_3 = 6;
            break;
            default:
            break;
        }

        // animacion de los slots

        const imagenes = document.getElementById('slot-1').querySelectorAll('img');
        const imagenes_2 = document.getElementById('slot-2').querySelectorAll('img');
        const imagenes_3 = document.getElementById('slot-3').querySelectorAll('img');
        let indice = 0;
        let indice_2 = 0;
        let indice_3 = 0;

        // Eliminar la clase 'active' de todas las imágenes
            imagenes.forEach(imagen => { imagen.classList.remove('active'); });
            imagenes_2.forEach(imagen => { imagen.classList.remove('active'); });
            imagenes_3.forEach(imagen => { imagen.classList.remove('active'); });

            // Agregar la clase 'active' a la imagen inicial
            imagenes[indice].classList.add('active');
            imagenes_2[indice_2].classList.add('active');
            imagenes_3[indice_3].classList.add('active');

            // Función para cambiar la imagen
            function cambiarImagen(i_final) {
                // Eliminar la clase 'active' de la imagen actual
                imagenes[indice].classList.remove('active');
            
                // Incrementar el índice y volver al principio si es necesario
                indice = (indice + 1) % imagenes.length;
            
                // Agregar la clase 'active' a la siguiente imagen
                imagenes[indice].classList.add('active');
                // Verificar si hemos alcanzado el índice final y establecer la imagen allí
                if (repeticiones == numeroDeRepeticiones) {
                    imagenes.forEach(imagen => { imagen.classList.remove('active'); });
                    imagenes[i_final].classList.add('active');
                    console.log(i_final);
                }
            }

            function cambiarImagen2(i_final) {
                // Eliminar la clase 'active' de la imagen actual
                imagenes_2[indice_2].classList.remove('active');
            
                // Incrementar el índice y volver al principio si es necesario
                indice_2 = (indice_2 + 1) % imagenes_2.length;
            
                // Agregar la clase 'active' a la siguiente imagen
                imagenes_2[indice_2].classList.add('active');
                // Verificar si hemos alcanzado el índice final y establecer la imagen allí
                if (repeticiones_2 == numeroDeRepeticiones2) {
                    imagenes_2.forEach(imagen => { imagen.classList.remove('active'); });
                    imagenes_2[i_final].classList.add('active');
                }
            }

            function cambiarImagen3(i_final) {
                // Eliminar la clase 'active' de la imagen actual
                imagenes_3[indice_3].classList.remove('active');
            
                // Incrementar el índice y volver al principio si es necesario
                indice_3 = (indice_3 + 1) % imagenes_3.length;
            
                // Agregar la clase 'active' a la siguiente imagen
                imagenes_3[indice_3].classList.add('active');
                // Verificar si hemos alcanzado el índice final y establecer la imagen allí
                if (repeticiones_3 == numeroDeRepeticiones3) {
                    imagenes_3.forEach(imagen => { imagen.classList.remove('active'); });
                    imagenes_3[i_final].classList.add('active');
                }
            }
            
            let numeroDeRepeticiones = Math.floor(Math.random() * 20) + 1;
            let numeroDeRepeticiones2 = Math.floor(Math.random() * 20) + 1;
            let numeroDeRepeticiones3 = Math.floor(Math.random() * 20) + 1;
            let indice_final = casilla_1; // Aquí asigna el índice final deseado
            let indice_final_2 = casilla_2; // Aquí asigna el índice final deseado
            let indice_final_3 = casilla_3; // Aquí asigna el índice final deseado
            let repeticiones = 0; // Variable para rastrear el número actual de repeticiones
            let repeticiones_2 = 0; // Variable para rastrear el número actual de repeticiones
            let repeticiones_3 = 0; // Variable para rastrear el número actual de repeticiones
            
            // Repetir el cambio de imagen el número de veces especificado
            for (let i = 0; i <= numeroDeRepeticiones; i++) {
                setTimeout(() => {
                    cambiarImagen(indice_final);
                    repeticiones++; // Incrementar el número de repeticiones
                }, i * 200); // Cambiar cada 500 milisegundos (puedes ajustar el tiempo según tu necesidad)
            }
            for (let i = 0; i <= numeroDeRepeticiones2; i++) {
                setTimeout(() => {
                    cambiarImagen2(indice_final_2);
                    repeticiones_2++; // Incrementar el número de repeticiones
                }, i * 300); // Cambiar cada 500 milisegundos (puedes ajustar el tiempo según tu necesidad)
            }
            for (let i = 0; i <= numeroDeRepeticiones3; i++) {
                setTimeout(() => {
                    cambiarImagen3(indice_final_3);
                    repeticiones_3++; // Incrementar el número de repeticiones
                }, i * 400); // Cambiar cada 500 milisegundos (puedes ajustar el tiempo según tu necesidad)
            }


        // Restablecer la animación y mostrar el mensaje después de 200ms
            setTimeout(() => {
                setAnimationActive(false);
                
            }, 400);
            setResults('...');
            setPremiosGanados(premiosGanados + 1);
            setPuntajeTotal(puntajeTotal + premio);
            /*
            console.log(`Tiro: ${tiro}`);
            console.log(`Slot 1: ${casilla_1}`);
            console.log(`Slot 2: ${casilla_2}`);
            console.log(`Slot 3: ${casilla_3}`);
            console.log(`Slot premio: ${slot}`);
            console.log(`Premio: ${premio}`);
            */
            guardarIntento(tiro,
                casilla_1,
                casilla_2,
                casilla_3,
                slot,
                premio);
    };

    /**
     * Empieza el return
     */

    if(!mostrarVistaJackpot){
        return(
            <div className='contenedor-principal'>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Jackpot</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0 pb--0">
                    <div className="container">
                        {mostrarInstrucciones ? (
                            <div className="row row--15 mt_dec--30">
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_iLPico03_2024.png" alt="icons Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Responde</a></h6>
                                                <p className="description">Una sencilla pregunta te revelará nuestro juego</p>
                                            </div>
                                            <span className="number-text">1</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico01_2024.png" alt="icons Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Da 1 Click</a></h6>
                                                <p className="description">1 Click de tu ratón activará la palanca</p>
                                            </div>
                                            <span className="number-text">2</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico02_2024.png" alt="Shape Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">¡Jackpot!</a></h6>
                                                <p className="description">Obtén 3 símbolos iguales y gana hasta 300 puntos</p>
                                            </div>
                                            <span className="number-text">3</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico03_2024.png" alt="Shape Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Persevera</a></h6>
                                                <p className="description">¡No te rindas!... Puedes participar en todos los Jackpot</p>
                                            </div>
                                            <span className="number-text">4</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ):null }
                    </div>
                </div>

                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0 pb--0">
                    <div className="container">
    
                        <div className="row row--15 mt_dec--30">
    
                           

                            <div className="container">
                            <div className="row row--15">
                                {/* Start Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>24 Octubre</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Próximo estreno</span>
                                    </div>
                                </div>
                                {/* End Single Card  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>0</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Puntos Ganados</span>
                                    </div>
                                </div>
                                {/* Start Single Card  */}

                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div className="rbt-card variation-03 rbt-hover">
                                        <div className="rbt-card-img">
                                        <h2 className="" style={{textAlign:'center'}}>0</h2>
                                        </div>
                                        <span className="subtitle" style={{textTransform:'uppercase', display: 'flex', justifyContent: 'center'}}>Jackpots Ganados</span>
                                    </div>
                                </div>

                            </div>
                            {/* End Card Area */}
                        </div>
    
                        </div>

                    </div>
                </div>

            
            <SeccionUno />
            
            </div>
        )
    }
    

    if (mostrarVistaJackpot){
        return (
            <div className='contenedor-principal'>
    
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Jackpot</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="rbt-counterup-area bg-color-white rbt-section-gap pt--0 pb--0">
                    <div className="container">
                        {mostrarInstrucciones ? (
                            <div className="row row--15 mt_dec--30">
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_iLPico03_2024.png" alt="icons Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Responde</a></h6>
                                                <p className="description">Una sencilla pregunta te revelará nuestro juego</p>
                                            </div>
                                            <span className="number-text">1</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico01_2024.png" alt="icons Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Da 1 Click</a></h6>
                                                <p className="description">1 Click de tu ratón activará la palanca</p>
                                            </div>
                                            <span className="number-text">2</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico02_2024.png" alt="Shape Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">¡Jackpot!</a></h6>
                                                <p className="description">Obtén 3 símbolos iguales y gana hasta 500 puntos</p>
                                            </div>
                                            <span className="number-text">3</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                    <div className="service-card service-card-6">
                                        <div className="inner">
                                            <div className="icon">
                                                <img src="/assets/images/micrositio/PLe_DChico03_2024.png" alt="Shape Images" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title"><a href="#">Persevera</a></h6>
                                                <p className="description">¡No te rindas!... Puedes participar en todos los Jackpot</p>
                                            </div>
                                            <span className="number-text">4</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ):null }
                        
                        {/**Empieza la máquina */}
                        {mostrarMaquina ? (
                        <div className="row justify-content-center" id="filaMaquina">
                            <div className="col-12 col-md-6">
                                <div className="jackpot-full" id='jackpot_full' data-id-jackpot=''
                                        data-limite-tiros=''
                                        data-tiros-realizados=''
                                        data-premios-ganados=''
                                        data-puntaje-total=''
                                        data-slot-premio=''
                                    >
    
                                    <div id="MaquinaJackpot" className="">
                                        <div className="jackpot-top">
                                            <img src="/assets/images/jackpot/top.svg" className="img-fluid" alt="" />
                                        </div>
                                        <div className="jackpot-middle">
                                            <img src="/assets/images/jackpot/middle.svg" className="img-fluid" alt="" />
                                            <div id="slots-container">
                                                <div id="slot-1">
                                                    <img src="/assets/images/jackpot/slot_1.svg" className="img-fluid active" alt="" />
                                                    <img src="/assets/images/jackpot/slot_2.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_3.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_4.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_5.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_6.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_7.svg" className="img-fluid" alt="" />
                                                </div>
                                                <div id="slot-2">
                                                    <img src="/assets/images/jackpot/slot_1.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_2.svg" className="img-fluid active" alt="" />
                                                    <img src="/assets/images/jackpot/slot_3.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_4.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_5.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_6.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_7.svg" className="img-fluid" alt="" />
                                                </div>
                                                <div id="slot-3">
                                                    <img src="/assets/images/jackpot/slot_1.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_2.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_3.svg" className="img-fluid active" alt="" />
                                                    <img src="/assets/images/jackpot/slot_4.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_5.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_6.svg" className="img-fluid" alt="" />
                                                    <img src="/assets/images/jackpot/slot_7.svg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="jackpot-bottom" style={{zIndex:'9999'}}>
                                            <img src="/assets/images/jackpot/bottom.svg" className="img-fluid" alt="" />
                                        </div>
                                        <div id="jackpot-palanca"
                                            onClick={() => {
                                                if (!clickDisabled) {
                                                  setClickDisabled(true);
                                                  jalarPalanca();
                                                }
                                              }}
                                                style={animationActive ? { transform: 'scaleY(0.1)' } : {}}>
                                            {mensajeVisible && (
                                                <div id="jackpot-mensaje-palanca" className="text-center">
                                                Jala la palanca y empieza a jugar
                                                </div>
                                            )}
                                            <div id="jackpot-esfera" className={animationActive ? 'animated' : ''}>
                                                <img
                                                src="/assets/images/jackpot/esfera.svg"
                                                className="img-fluid"
                                                alt=""
                                                />
                                                <img src="/images/jackpot/esfera.svg" className="img-fluid" alt="" />
                                            </div>
                                            <img
                                                src="/assets/images/jackpot/palanca.svg"
                                                className="img-fluid"
                                                alt=""
                                                
                                            />
                                        </div>
                                    </div>  
                                </div> {/* fin de jackpot_full */}                         
                            </div>
                        </div>
                        ):null }

                        {mostrarTrivia ? (
                            <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30">
                                <div className="rbt-course-feature-inner">
                                    <div className="section-title">
                                        <h4 className="rbt-title-style-3">Contesta la siguiente trivia para poder participar</h4>
                                    </div>
                                            <div className='container'>
                                                {listadoPreguntas && listadoPreguntas.map((pregunta, index) => {
                                                    
                                                
                                                    return(
                                                        <div key={index} className={index === step ? "" : "d-none"}>
                                                            <RadioButtonGroupTrivia
                                                                groupName={`pregunta-${pregunta.id}`}
                                                                id_pregunta={pregunta.id}
                                                                pregunta={pregunta.pregunta}
                                                                options={[
                                                                ...(pregunta.respuesta_a ? [{ label: pregunta.respuesta_a, value: 'A' }] : []),
                                                                ...(pregunta.respuesta_b ? [{ label: pregunta.respuesta_b, value: 'B' }] : []),
                                                                ...(pregunta.respuesta_c ? [{ label: pregunta.respuesta_c, value: 'C' }] : []),
                                                                ...(pregunta.respuesta_d ? [{ label: pregunta.respuesta_d, value: 'D' }] : []),
                                                                ]}
                                                                onChange={handleGroupChange}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                </div>

                                                    <div className='btn-container'>
                                                        <button className="rbt-btn btn-gradient icon-hover" disabled={step === 0} onClick={handlePrevious}>
                                                            <span className="btn-text">Anterior</span>
                                                            <span className="btn-icon"><i className="feather-arrow-left"></i></span>
                                                        </button>

                                                        {step === 0 ? (
                                                            <button className="rbt-btn btn-gradient icon-hover" onClick={guardarRespuestasjackpot}>
                                                                <span className="btn-text">Guardar respuestas</span>
                                                                <span className="btn-icon"><i className="feather-save"></i></span>
                                                            </button>
                                                        ) : (
                                                            <button className="rbt-btn btn-gradient icon-hover" onClick={handleNext}>
                                                                <span className="btn-text">Siguiente</span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            </button>
                                                        )}
                                                    </div>
                                            </div>
                                        </div>
                                    ):null}


                        {/**Mensaje de ganador */}
                        {console.log('resultados '+mostrarResultados)}
                        {mostrarResultados ? (
                        <div className="d-block card card-body" id="MensajeGanador">
                            {console.log('trivia:  '+triviaCorrecta)}
                            {!triviaCorrecta ? (
                                <>
                                    <h1>¡No respondiste correctamente! </h1>
                                    <p > El próximo Jackpot de P-Learning se anunciará muy pronto. ¡Tienes más oportunidades! </p>
                                </>
                            ):null}
                             {listadoIntentos && listadoIntentos.map((intento, index) => {
                                if(intento.puntaje>0){
                                    let sl_1 = intento.slot_1+1;
                                    let sl_2 = intento.slot_2+1;
                                    let sl_3 = intento.slot_3+1;
                                    return(
                                        <div key={index}>
                                        <div>
                                            <h2> ¡TENEMOS UN GANADOR!</h2>
                                            <img src={`/assets/images/jackpot/slot_${sl_1}.svg`} width='100px' alt="" />
                                            <img src={`/assets/images/jackpot/slot_${sl_2}.svg`} width='100px' alt="" />
                                            <img src={`/assets/images/jackpot/slot_${sl_3}.svg`} width='100px' alt="" />
                                        </div><h1 className="">¡<span id="PuntajeSlot">{intento.puntaje}</span> Puntos!</h1>
                                        <h3 id="MensajeSlot" className="">¡Un regalo de Panduit, con mucho cariño!</h3>
                                        <p>*Los añadiremos a tu gran total de puntos P-Learning. ¡Participa en el próximo Jackpot para seguir ganando!*</p>
                                        </div>
                                    );
                                }else{
                                    let sl_1 = intento.slot_1+1;
                                    let sl_2 = intento.slot_2+1;
                                    let sl_3 = intento.slot_3+1;
                                   return(
                                    <div key={index}>
                                    <img src={`/assets/images/jackpot/slot_${sl_1}.svg`} width='100px' alt="" />
                                    <img src={`/assets/images/jackpot/slot_${sl_2}.svg`} width='100px' alt="" />
                                    <img src={`/assets/images/jackpot/slot_${sl_3}.svg`} width='100px' alt="" />
            
                                        <h1>No hay puntos esta vez, pero ¡no te rindas! </h1>
                                        <p > El próximo Jackpot de P-Learning se anunciará muy pronto. ¡Tienes más oportunidades! </p>
                                    </div>
                                   )
                                }
                             })}
                            
    
                            
    
                            
                                        
                        </div>
                        ):null }
                        {/**Empieza listado de cards */}
                        
    
                    <div className="rbt-conatct-area bg-color-white pt--60 rbt-section-gapBottom pb--60">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 sal-animate" data-sal="slide-up" data-sal-delay="150" data-sal-duration="800">
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SeccionUno />
            </div>
        );
        
    }
}

export function Check10() {

    const { cuentaPL, setCuentaPL } = useVariablesGlobales();
    const { region } = useParams();

    const [datosUsuario, setDatosUsuario] = useState(null);
    const [puntajeUsuario, setPuntajeUsuario] = useState(null);
    const [puntajeTotal, setPuntajeTotal] = useState(0);
    const [regionUsuario, setRegionUsuario] = useState(null);
    const [consultaRegion, setConsultaRegion] = useState(null);
    const [mostrarTop10, setMostrsarTop10] = useState(false)
    const [top10isLoading, setTop10IsLoading] = useState(true); // Estado de carga
    const [top10Mensaje, setTop10Mensaje] = useState(''); // Estado de carga
    const [puntajesTop, setPuntajesTop] = useState(null);
    const [usuariosTop, setUsuariosTop] = useState(null);
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    const [premioExperiencia, setPremioExperiencia] = useState('Experiencia Panduit 2025');
    const [premioBono, setPremioBono] = useState('Bono $3,000 MXN');
    const [fechaCorte, setFechaCorte] = useState('');



    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        axios.get('https://plsystem.quarkservers2.com/api/full_check_puntaje', {
            headers: { 'Authorization': `Bearer ${token}` },
            params: { id: id_local, id_temporada: 9 }
        })
        .then(response => {
            
            setDatosUsuario(response.data);
            setPuntajeUsuario(response.data.puntaje);
            let suma = (response.data.puntaje.evaluaciones || 0) + (response.data.puntaje.visualizaciones || 0) + (response.data.puntaje.trivia || 0) + (response.data.puntaje.jackpots || 0) + (response.data.puntaje.extra || 0);
            setPuntajeTotal(suma);
            setRegionUsuario(response.data.distribuidor.region);
            if(response.data.distribuidor.nivel=='Completo'||response.data.distribuidor.nivel=='Medio'){
                listaTop10();
            }else{
                setTop10Mensaje('Tu distribuidor no cuenta con el nivel necesario para ver el top 10.');
            }
            
        })
        .catch(error => console.log(error));
    }, [cuentaPL]);


    /**
     * Esta es la función del top 10
     */

    const listaTop10 = async () => {
        console.log('Se inicia el proceso del top');
        setTop10IsLoading(true);
        const region_local = JSON.parse(localStorage.getItem('Region'));
        // Aquí asegúrate de que siempre tengamos una región válida
        const regionActual = region || region_local;

        if (!regionActual) {
            console.log('No hay región disponible');
            setTop10IsLoading(false);
            setTop10Mensaje('No se pudo cargar el top 10 por que tu región no fue detectada correctamente.');
            return; // Salimos si no hay región
        }
        
        try {
            const response = await axios.get('https://plsystem.quarkservers2.com/api/top_10_region', {
                params: { 
                    id: cuentaPL,
                    region: regionActual
                    //region: 'México'
                }
            });
            console.log('Se envió la info');
            // Obtengo los datos del top 10
            setConsultaRegion(regionActual);
            setPuntajesTop(response.data.puntajes_top);
            //console.log(response.data.puntajes_top);
            setUsuariosTop(response.data.usuarios);
            //console.log(response.data.usuarios);
            setFechaCorte(format(new Date(response.data.fecha_corte), 'dd/MM/yyyy HH:mm') );
            if(!response.data.puntajes_top){
                setTop10Mensaje('No hay datos en este top 10');
            }
            switch (regionActual) {
                case 'Interna':
                case 'interna':
                    setPremioExperiencia('Experiencia Panduit 2025');
                    setPremioBono('Bono de $6,000 MXN');
                    
                    break;
                case 'México':
                case 'mexico':
                case 'Mexico':
                    setPremioExperiencia('Experiencia Panduit 2025');
                    setPremioBono('Bono de $6,000 MXN');
                
                break;
                case 'RoLA':
                case 'rola':
                    setPremioExperiencia('Experiencia Panduit 2025');
                    setPremioBono('Bono de 200 USD');
                
                break;
            
                default:
                    setPremioExperiencia('Experiencia Panduit 2025');
                    setPremioBono('Bono de $3,000 MXN');
                    break;
            }
        
            setMostrsarTop10(true);
        
            
            
            
        } catch (error) {
            console.log(error);
            setTop10Mensaje('No se pudo cargar el top 10 por un error en el servidor');
        }
        finally {
            setTop10IsLoading(false); // Al terminar la obtención de datos (éxito o error)
          }
    };

    const formatNumberWithCommas = (number) => {
        if (number == null) return '0';
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Enero es 0!
        const year = today.getFullYear();
      
        return `${day}/${month}/${year}`;
      };

   


    if(!mostrarTop10){
        return(
            <div>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Top 10 </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white" style={{paddingTop: '0px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--60">
                                
                                    <div className="description has-medium-font-size mt--20 mb--0">
                                    <div className="rbt-author-info" style={{fontSize:'20px'}}>
                                        {top10Mensaje}
                                    </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if(mostrarTop10){
    return (
        <div>
            <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">Top 10 - { consultaRegion === "mexico" ? "México" : consultaRegion === "rola" ? "Latinoamérica" : consultaRegion} </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white" style={{paddingTop: '0px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--60">
                            
                                <div className="description has-medium-font-size mt--20 mb--0">
                                <div className="rbt-author-info" style={{fontSize:'20px'}}>
                                    Bienvenido, <span className="title" style={{fontWeight:700}}>{datosUsuario.usuario.nombre}</span>
                                </div>
                                Te presentamos la clasificación actual de tu región. Si no apareces en la lista, te invitamos a seguir participando en todas las actividades y así mejorar tu posición.
                                </div>
                                <div className="info-table">
                                    <table>
                                        <tbody style={{background:'#f4f4f4'}}> 
                                            <tr>
                                                <td>Cuentas con:  <span className="designation theme-gradient" style={{fontWeight:700}}>{formatNumberWithCommas(puntajeTotal)} puntos</span></td>
                                                <td>Región: { consultaRegion === "mexico" ? "México" : consultaRegion === "rola" ? "Latinoamérica" : consultaRegion}</td>
                                            </tr>
                                            <tr>
                                                <td>Última actualización: {fechaCorte}</td>
                                                <td>Puedes ganar: <span className="designation theme-gradient" style={{fontWeight:700}}>{premioExperiencia}</span> o un <br/><span className="designation theme-gradient" style={{fontWeight:700}}>{premioBono}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive mobile-table-750">
                        {top10isLoading ? (
                            <h3>Cargando...</h3> // Mostrar "Cargando" cuando isLoading es true
                        ) : usuariosTop.length > 0 ? (
                            <table className="rbt-table table table-borderless">
                            <thead>
                                <tr>
                                <th className="text-center" style={{ width: '120px' }}>
                                    LUGAR
                                </th>
                                <th style={{ width: '350px' }}>NOMBRE</th>
                                <th style={{ width: '150px' }}>COMPAÑIA</th>
                                <th style={{ width: '150px' }}>PUNTOS</th>
                                <th className="d-none">OCULTO</th>
                                <th className="d-none" style={{ width: '180px' }}>
                                    PREMIO FINAL
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {usuariosTop.map((usuario, index) => {
                                const puntajeIndex = puntajesTop.indexOf(usuario.puntaje);

                                return (
                                    <tr key={index}>
                                    <td className="text-center">
                                        {puntajeIndex !== -1 ? puntajeIndex + 1 : 'N/A'}
                                    </td>
                                    <td>
                                        <div className="rbt-author-meta mb--10">
                                        <div className="rbt-avater">
                                            <img
                                            src={`https://plsystem.quarkservers2.com/img/usuarios/${usuario.imagen}`}
                                            alt="Avatar"
                                            />
                                        </div>
                                        <div className="rbt-author-info">
                                            {usuario.nombre} {usuario.apellidos}
                                        </div>
                                        </div>
                                    </td>
                                    <td>{usuario.distribuidor}</td>
                                    <td>{usuario.puntaje}</td>
                                    <td className="d-none">{usuario.puntaje_oculto}</td>
                                    <td className="d-none">
                                        {usuario.premio === 'experiencia' ? (
                                        <span className="rbt-btn btn-xs bg-color-award-opacity radius-round color-award">
                                            <i className="feather-award pl--0 pr--5"></i>
                                            {premioExperiencia}
                                        </span>
                                        ) : usuario.premio === 'bono' ? (
                                        <span className="rbt-btn btn-xs bg-color-success-opacity radius-round color-success">
                                            <i className="feather-dollar-sign pl--0 pr--5"></i>
                                            {premioBono}
                                        </span>
                                        ) : !usuario.premio ? (
                                        '-'
                                        ) : null}
                                    </td>
                                    </tr>
                                );
                                })}
                            </tbody>
                            </table>
                        ) : (
                            <h3>Prueba: {top10Mensaje}</h3> // Mostrar si no hay usuariosTop
                        )}
                        </div>
                    

                    <div className="row g-5 align-items-center mt--10">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="content">
                                <div className="inner text-center">
                                    <div className="slider-btn rbt-button-group justify-content-center">
                                        <a className="rbt-btn btn-gradient hover-icon-reverse" href="/usuario/perfil">
                                            <span className="icon-reverse-wrapper">
                                                <span className="btn-text">Revisa tu puntaje</span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            </span>
                                        </a>
                                        <a className="rbt-btn hover-icon-reverse btn-white" href="/TyC">
                                            <span className="icon-reverse-wrapper">
                                                <span className="btn-text">Lee nuestros TyC</span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
    }
}

export function Incentivos() {
return (
    <div>

            <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">Incentivos</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
      <div className="rbt-course-banner-area rbt-section-gap bg-color-white pt--0">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center mb--60">
                        {/* <p className="description has-medium-font-size mt--20">¡El Jackpot está disponible para ti hoy! ¡Es tu oportunidad de ganar grande! ¡No dejes pasar esta increíble oportunidad!</p>
                        
                            <div className="rbt-button-group mt--30">
                                <a className="rbt-btn btn-gradient hover-icon-reverse" href="/jackpot">
                                    <span className="icon-reverse-wrapper">
                                        <span className="btn-text">Jackpot</span>
                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                    </span>
                                </a>

                            </div> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="inner">
                        <div className="row g-5"> 
                            {/* <div className="col-lg-12">
                                <div className="modern-course-features-box h-100">
                                    <div className="inner" style={{display: 'block'}}>
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PLe_IncentivosA_bann_2024.png" alt="Image" />
                                        </div>
                                        <div className="content">
                                            <br />
                                            <span className="rbt-badge-6 bg-third-opacity">Recompensas panduit 2024</span>
                                            <h2 className="title mt--10">Convierte tus Créditos en premios especiales</h2>
                                            <p className="text-white">Ese año podrás elegir entre 3 grandes premios edición especial</p>
                                            <a className="rbt-btn btn-sm" href="/recompensa">Ir a Recompensas</a>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Feature Box  */}

                            {/* Start Single Feature Box  */}
                            <div className="col-lg-6">
                                <div className="modern-course-features-box one-colume-grid h-100">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PL_IncentivosA_bann_2024.png" alt="Image" />
                                        </div>
                                        <div className="content">
                                            <a href="/jackpot">
                                            <h2 className="title">Jackpot</h2>
                                            </a>
                                            <p className="text-white">Cada mes tendrás una oportunidad para jalar la palanca y ganar hasta 500 Puntos
                                            </p>
                                            <a className="rbt-btn btn-sm" href="/jackpot">Ir a Jackpot</a>
                                            {/* <ul className="course-feature-list">
                                                <li>
                                                    <div className="icon">
                                                        <img src="/assets/images/icons/card-icon-11.png" alt="Image Icon" />
                                                    </div>
                                                    <div className="feature-content">
                                                        <h4 className="featute-title">5000+ <span>Get Award</span></h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon">
                                                        <img src="/assets/images/icons/card-icon-12.png" alt="Image Icon" />
                                                    </div>
                                                    <div className="feature-content">
                                                        <h4 className="featute-title">10K+ <span>Zero to career </span></h4>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Feature Box  */}

                            {/* Start Single Feature Box  */}
                            <div className="col-lg-6">
                                <div className="modern-course-features-box grid-content-reverse h-100">
                                    <div className="inner">
                                        <div className="rbt-image-gallery-wrapper">
                                            <div className="swiper modern-course-carousel-activation rbt-arrow-between swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                                <div className="swiper-wrapper" id="swiper-wrapper-84f084b27478b2101" aria-live="polite" style={{transform: 'translate3d(-558px, 0px, 0px)', transitionDuration: '0ms'}}><div className="swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="2" style={{width: '558px '}} role="group" aria-label="3 / 3">
                                                        <div className="thumbnail mt--0">
                                                            <img src="/assets/images/course/single-course-09.jpg" alt="Image" />
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide swiper-slide-active" data-swiper-slide-index="0" style={{width: '558px '}} role="group" aria-label="1 / 3">
                                                        <div className="thumbnail mt--0">
                                                            <img src="/assets/images/micrositio/PL_IncentivosB_bann_2024.png" alt="Image" />
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide swiper-slide-next" data-swiper-slide-index="1" style={{width: '558px '}} role="group" aria-label="2 / 3">
                                                        <div className="thumbnail mt--0">
                                                            <img src="/assets/images/course/single-course-08.jpg" alt="Image" />
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="2" style={{width: '558px '}} role="group" aria-label="3 / 3">
                                                        <div className="thumbnail mt--0">
                                                            <img src="/assets/images/course/single-course-09.jpg" alt="Image" />
                                                        </div>
                                                    </div>
                                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="0" style={{width: '558px '}} role="group" aria-label="1 / 3">
                                                        <div className="thumbnail mt--0">
                                                            <img src="/assets/images/course/single-course-07.jpg" alt="Image" />
                                                        </div>
                                                    </div></div>

                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                                        </div>
                                        <div className="content">
                                            <h2 className="title">I Love Panduit</h2>
                                            <p className="text-white">¡Nuestro clásica trivia de velocidad se mantiene!
                                            <a className="rbt-btn-link ml--30" style={{fontSize:'18px', color:'#f0b323'}} href="/ilovepanduit">Ir a Trivia<i className="feather-arrow-right"></i></a>
                                            </p>
                                            
                                            <ul className="rbt-list-style-2 flex-wrap">
                                                <li className="text-white"><i className="feather-check"></i>Accesa</li>
                                                <li className="text-white"><i className="feather-check"></i>Participa</li>
                                                <li className="text-white"><i className="feather-check"></i>Responde</li>
                                                <li className="text-white"><i className="feather-check"></i>Gana</li>
                                                <li className="text-white"><i className="feather-check"></i>Acumula</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Feature Box  */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

    <BannerWhatsapp />
    </div>
);
}

// Funciones de Radio Button
function RadioButtonGroup({ groupName, id_pregunta, options, onChange }) {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const handleOptionChange = (event) => {
      const value = event.target.value;
      setSelectedOption(value);
      onChange(id_pregunta, value);
    };
  
    return (
      <div>
        {options.map((option) => (
            <div className="form-check" key={option.value}>
                <input 
                    className="form-check-input"
                    type="radio" 
                    name={groupName}
                    id={`respuesta-${id_pregunta}-${option.value}`}
                    value={option.value}
                    onChange={handleOptionChange} />
                <label 
                    className="form-check-label" 
                    htmlFor={`respuesta-${id_pregunta}-${option.value}`} >
                    {option.label}
                </label>
            </div>
        ))}
      </div>
    );
}

function RadioButtonGroupJackpot({ groupName, id_pregunta, options, onChange }) {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onChange(id_pregunta, value);
    };

    return (
        <div>
        {options.map((option) => (
            <div className="form-check" key={option.value}>
                <input 
                    className="form-check-input"
                    type="radio" 
                    name={groupName}
                    id={`respuesta-${id_pregunta}-${option.value}`}
                    value={option.value}
                    onChange={handleOptionChange} />
                <label 
                    className="form-check-label" 
                    htmlFor={`respuesta-${id_pregunta}-${option.value}`} >
                    {option.label}
                </label>
            </div>
        ))}
        </div>
    );
}

function RadioButtonGroupTrivia({ groupName, id_pregunta, pregunta, options, onChange }) {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onChange(id_pregunta, value);
    };
    return (
        <div className="step wizard-step" >
            <h3 className="main_question">{pregunta}</h3>
            {options.map((option) => (
                <div className="form-group" key={option.value}>
                    <label 
                        className="container_radio version_2"
                        htmlFor={`respuesta-${id_pregunta}-${option.value}`}
                        >{option.label}
                        <input
                            type="radio" 
                            name={groupName}
                            id={`respuesta-${id_pregunta}-${option.value}`}
                            value={option.value} 
                            onChange={handleOptionChange} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            ))}                              
        </div>
    );
}

export default Temporada;